import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { FileText } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { useDocument } from '@nandorojo/swr-firestore'
import Loader from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import OLoader from './OLoader'
import AButton from '../atoms/AButton'
import {
  store,
  files,
  auth,
  fieldValueServerTimestamp,
  firestoreTimestamp,
} from '../services/Firebase'
import CONST from '../config/Constants'
import MReportPreview from '../molecules/MReportPreview'
import OManageUsersForReport from '../organisms/OManageUsersForReport'

// const SAVING_STATUS = {
//   awaiting: 'awaiting',
//   active: 'active',
//   done: 'done',
// }

/**
 *
 * DroppableFileContainer
 */
function DroppableFileContainer({
  onFileAccepted,
  isSaving,
  accept,
  fileExtensionLabel,
  highlightMissing = false,
}) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        onFileAccepted(file)
      }
    },
    [onFileAccepted]
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    accept: accept,
    onDrop: onDrop,
  })

  return (
    <>
      {isSaving ? (
        <OLoader height={50} width={50} />
      ) : (
        <div
          {...getRootProps()}
          className={`${
            highlightMissing ? 'border-red-300' : 'border-gray-300'
          } mt-2 flex flex-grow justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md`}
        >
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input {...getInputProps()} />
            <p className="mt-1 text-sm text-gray-600">
              {isDragActive && isDragAccept ? (
                <>
                  <span className="font-medium text-hub-blue hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1">
                    Drop file here
                  </span>
                </>
              ) : (
                <>
                  <span className="font-medium text-hub-blue hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1">
                    Upload a file
                  </span>
                  <span>or drop here</span>
                </>
              )}
            </p>
            <p className="mt-1 text-xs text-gray-500">{fileExtensionLabel}</p>
          </div>
        </div>
      )}
    </>
  )
}
/**
 *
 * CoverPreview
 */
function CoverPreview({ coverUrl, file, onRemoveFile, hideRemove = false }) {
  let urledFile = coverUrl
  if (file) {
    urledFile = URL.createObjectURL(file)
  }

  return (
    <div className="mt-2 flex flex-col justify-center items-center content-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
      <div className="flex flex-col flex-grow justify-center items-center content-center">
        <img src={urledFile} alt="Cover" className="rounded-md"></img>
      </div>
      {!hideRemove && (
        <div className="mt-2 flex flex-col flex-grow-0">
          <AButton onClick={onRemoveFile} fullBorderRadius>
            Change cover
          </AButton>
        </div>
      )}
    </div>
  )
}

/**
 *
 * ReportPreview
 */
function ReportFilePreview({
  fileName,
  file,
  onRemoveFile,
  hideRemove = false,
}) {
  let fileNameToDisplay = fileName
  if (file) {
    fileNameToDisplay = file.name
  }
  return (
    <div className="mt-2 flex flex-col flex-grow justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
      <div className="flex flex-col flex-grow justify-center items-center content-center">
        <FileText className="mt-2 h-12 w-12 text-gray-400" />
        <p className="mt-1 mb-4 text-sm text-gray-600 break-word text-center ">
          {fileNameToDisplay}
        </p>
      </div>
      {!hideRemove && (
        <div className="mt-2 flex flex-col flex-grow-0">
          <AButton onClick={onRemoveFile} fullBorderRadius>
            Change report PDF
          </AButton>
        </div>
      )}
    </div>
  )
}

/**
 *
 * RemoteReportFilePreview
 */
function RemoteReportFilePreview({ organizationId, reportId, fileName }) {
  const [lodingDownloadLink, setLodingDownloadLink] = useState(false)
  const startDownload = async () => {
    setLodingDownloadLink(true)
    const url = await files
      .ref(`${organizationId}/${reportId}/report.pdf`)
      .getDownloadURL()
    window.location.assign(url)
    setLodingDownloadLink(false)
  }
  return (
    <div className="mt-2 flex flex-col flex-grow justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
      <div className="flex flex-col flex-grow justify-center items-center content-center">
        <FileText className="mt-2 h-12 w-12 text-gray-400" />
        <p className="mt-1 mb-4 text-sm text-gray-600 break-word text-center ">
          {fileName}
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-grow-0">
        <AButton
          onClick={startDownload}
          isLoading={lodingDownloadLink}
          loadingIcon={false}
          fullBorderRadius
          className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
        >
          Download
        </AButton>
      </div>
    </div>
  )
}

/**
 *
 * ReportMetadaEdit
 */
function ReportMetadaEdit({ report, reportLoading }) {
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)

  const { register, handleSubmit, formState, reset, watch, setValue } = useForm(
    {
      defaultValues: report,
    }
  )

  //To make sure, when data is updated on the server (also after save in this component)
  //reset form with data after new report data received
  useEffect(() => {
    if (!reportLoading && report) {
      reset(report)
    }
  }, [report, reportLoading, reset])

  const { isDirty, dirtyFields } = formState

  const handleSaveChanges = useCallback(
    async (data) => {
      function getUpdateObject(beforeObj, afterObj) {
        const fieldsToTrack = [
          'authors',
          'name',
          'fileName',
          'description',
          'radarURL',
          'createdAt',
          'reportType',
          'immersionVideoURL',
        ]
        const objToReturn = {}
        fieldsToTrack.forEach((fieldName) => {
          if (fieldName === 'createdAt') {
            if (
              beforeObj[fieldName]['seconds'] !== afterObj[fieldName]['seconds']
            ) {
              objToReturn[fieldName] = afterObj[fieldName]
            }
          } else if (beforeObj[fieldName] !== afterObj[fieldName]) {
            objToReturn[fieldName] = afterObj[fieldName].trim()
          }
        })
        return objToReturn
      }

      setIsSaving(true)

      const changeObj = getUpdateObject(report, data)
      const promises = []

      // console.log(changeObj)

      const firestoreUpdatePromise = store
        .collection(CONST.frirebaseCollections.reports)
        .doc(report.id)
        .update({
          ...changeObj,
          lastUpdatedBy: auth.currentUser.uid,
          lastUpdatedAt: fieldValueServerTimestamp,
        })

      promises.push(firestoreUpdatePromise)
      if (changeObj['fileName']) {
        //download filename was changed, we need to update it on firebase storage
        const reportFileRef = files.ref(
          `${report.organizationId}/${report.id}/report.pdf`
        )
        const updateMetadataPromise = reportFileRef.updateMetadata({
          contentDisposition: `attachment; filename=${changeObj['fileName']}.pdf`,
        })
        promises.push(updateMetadataPromise)
      }

      await Promise.all(promises)
      setIsSaving(false)
      setAllDone(true)
    },
    [report]
  )

  const watchCreatedAt = watch('createdAt', report?.createdAt)

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Report information
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(handleSaveChanges)} method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Report title
                    </label>
                    <input
                      id="name"
                      name="name"
                      ref={register({ required: true })}
                      required
                      className={`${
                        dirtyFields.name
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    />
                  </div>
                  <div className="mt-6">
                    <label
                      htmlFor="authors"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Report authors
                    </label>
                    <input
                      id="authors"
                      name="authors"
                      ref={register({ required: true })}
                      required
                      className={`${
                        dirtyFields.authors
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    />
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="description"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <div className="rounded-md shadow-sm">
                      <textarea
                        id="description"
                        rows="3"
                        name="description"
                        ref={register({ required: true })}
                        required
                        className={`${
                          dirtyFields.description
                            ? 'border-orange-300'
                            : 'border-gray-300'
                        } form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                      ></textarea>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description of 1-2 sentences.
                    </p>
                  </div>
                  <div className="mt-6">
                    <label className="block text-sm leading-5 font-medium text-gray-700">
                      Report type
                    </label>
                    <fieldset className="mt-4">
                      <legend className="sr-only">Notification method</legend>
                      <div className="space-y-4">
                        {Object.keys(CONST.reportTypes).map((type) => (
                          <div key={type} className="flex items-center">
                            <input
                              id={type}
                              name="reportType"
                              value={type}
                              ref={register({ required: true })}
                              type="radio"
                              defaultChecked={type === 'debrief'}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label
                              htmlFor={type}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {CONST.reportTypes[type]}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  <div className="mt-6">
                    <div className="">
                      <label
                        htmlFor="fileName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Downloaded file name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="fileName"
                          name="fileName"
                          ref={register({ required: true })}
                          className={`${
                            dirtyFields.fileName
                              ? 'border-orange-300'
                              : 'border-gray-300'
                          } form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                          placeholder=""
                        />
                        <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          .pdf
                        </span>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        How would you like the downloaded file to be named. Skip
                        the .pdf
                      </p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="radarURL"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Radar url
                    </label>
                    <input
                      type="url"
                      id="radarURL"
                      name="radarURL"
                      ref={register({ required: false })}
                      className={`${
                        dirtyFields.radarURL
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    />
                  </div>
                  <div className="mt-6">
                    <label
                      htmlFor="immersionVideoURL"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Immersion Video URL
                    </label>
                    <input
                      type="url"
                      id="immersionVideoURL"
                      name="immersionVideoURL"
                      ref={register({ required: false })}
                      className={`${
                        dirtyFields.immersionVideoURL
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Use only Vimeo links.
                    </p>
                  </div>
                  <div className="mt-6">
                    <label
                      htmlFor="createdAt"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Pulished date (for display purpose)
                    </label>

                    <DatePicker
                      id="createdAt"
                      ref={register({ name: 'createdAt', required: false })}
                      selected={watchCreatedAt.toDate()}
                      onChange={(date) =>
                        setValue(
                          'createdAt',
                          firestoreTimestamp.fromDate(date),
                          { shouldDirty: true }
                        )
                      }
                      className={`${
                        dirtyFields.createdAt
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Selecting the date will not publish the report
                      automatically.
                    </p>
                  </div>
                </div>

                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  {isSaving && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <Loader
                        type="Rings"
                        color="#5850ec"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                  {allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Report information updated{' '}
                          <span role="img" aria-label="strong">
                            💪
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {!isSaving && !allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p
                          className={`${
                            isDirty ? 'text-hub-blue' : 'text-gray-600'
                          } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                        >
                          {isDirty ? 'Save changes?' : 'No changes'}
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            disabled={!isDirty}
                            onClick={() => {
                              reset()
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton type="submit" disabled={!isDirty}>
                            Save changes
                          </AButton>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * ReportCoverEdit
 */
function ReportCoverEdit({ organizationId, reportId }) {
  const [coverFile, setCoverFile] = useState(null)
  const [originalCoverUrl, setOriginalCoverUrl] = useState(null)

  useEffect(() => {
    files
      .ref(`${organizationId}/${reportId}/cover.png`)
      .getDownloadURL()
      .then((url) => {
        setOriginalCoverUrl(url)
      })
  }, [organizationId, reportId])

  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)

    const coverRef = files.ref(`${organizationId}/${reportId}/cover.png`)

    await coverRef.put(coverFile)
    await coverRef.getDownloadURL().then((url) => {
      setOriginalCoverUrl(url)
    })
    setCoverFile(null)
    setIsSaving(false)
    setAllDone(true)
  }, [coverFile, organizationId, reportId])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  const onCoverUploaded = (file) => {
    setCoverFile(file)
  }

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Report cover
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="mt-6 grid grid-cols-2 gap-6">
                    <div className="flex flex-col col-span-2 md:col-span-1">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        Current cover
                      </label>
                      {originalCoverUrl && (
                        <CoverPreview
                          coverUrl={originalCoverUrl}
                          hideRemove={true}
                        />
                      )}
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1 flex-grow">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        New cover
                      </label>
                      {coverFile ? (
                        <CoverPreview file={coverFile} hideRemove={true} />
                      ) : (
                        <DroppableFileContainer
                          onFileAccepted={onCoverUploaded}
                          onFileRemove={() => {
                            alert('Remove file')
                          }}
                          accept="image/png"
                          file={coverFile}
                          fileExtensionLabel=".PNG"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  {isSaving && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <Loader
                        type="Rings"
                        color="#5850ec"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                  {allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Cover updated successfully{' '}
                          <span role="img" aria-label="star">
                            🌟
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {!isSaving && !allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p
                          className={`${
                            coverFile ? 'text-hub-blue' : 'text-gray-600'
                          } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                        >
                          {coverFile ? 'Save changes?' : 'No changes'}
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            disabled={!coverFile}
                            onClick={() => {
                              setCoverFile(null)
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton
                            onClick={handleSaveChanges}
                            disabled={!coverFile}
                          >
                            Save changes
                          </AButton>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * ReportFileEdit
 */
function ReportFileEdit({ organizationId, reportId, reportFileName }) {
  const [reportFile, setReportFile] = useState(null)

  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)

    const reportRef = files.ref(`${organizationId}/${reportId}/report.pdf`)

    await reportRef.put(reportFile, {
      contentDisposition: `attachment; filename=${reportFileName}.pdf`,
    })

    setReportFile(null)
    setIsSaving(false)
    setAllDone(true)
  }, [organizationId, reportFile, reportFileName, reportId])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  const onReportFileUploaded = (file) => {
    setReportFile(file)
  }

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Report file
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="mt-6 grid grid-cols-2 gap-6">
                    <div className="flex flex-col col-span-2 md:col-span-1">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        Current report file
                      </label>
                      <RemoteReportFilePreview
                        organizationId={organizationId}
                        reportId={reportId}
                        fileName={`${reportFileName}.pdf`}
                      />
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        New report file
                      </label>
                      {reportFile ? (
                        <ReportFilePreview file={reportFile} hideRemove />
                      ) : (
                        <DroppableFileContainer
                          onFileAccepted={onReportFileUploaded}
                          onFileRemove={() => {
                            alert('Remove file')
                          }}
                          accept="application/pdf"
                          file={reportFile}
                          fileExtensionLabel=".PDF"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  {isSaving && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <Loader
                        type="Rings"
                        color="#5850ec"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                  {allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Report file saved successfully{' '}
                          <span role="img" aria-label="construction">
                            🏗
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {!isSaving && !allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p
                          className={`${
                            reportFile ? 'text-hub-blue' : 'text-gray-600'
                          } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                        >
                          {reportFile ? 'Save changes?' : 'No changes'}
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            disabled={!reportFile}
                            onClick={() => {
                              setReportFile(null)
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton
                            onClick={handleSaveChanges}
                            disabled={!reportFile}
                          >
                            Save changes
                          </AButton>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * ReportPublishStatusEdit
 */
function ReportPublishStatusEdit({ report }) {
  const [isSaving, setIsSaving] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [allDone, setAllDone] = useState(false)

  const [coverURL, setCoverURL] = useState(null)

  useEffect(() => {
    files
      .ref(`${report.organizationId}/${report.id}/cover.png`)
      .getDownloadURL()
      .then((url) => {
        setCoverURL(url)
      })
  }, [report.id, report.organizationId])

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)
    setShowConfirmation(false)
    await store
      .collection(CONST.frirebaseCollections.reports)
      .doc(report.id)
      .update({
        isPublished: !report.isPublished,
        updatedBy: auth.currentUser.uid,
        lastUpdatedAt: fieldValueServerTimestamp,
      })

    setIsSaving(false)
    setAllDone(true)
  }, [report.id, report.isPublished])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Publish status
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                When report gets published emails will be sent automatically to
                users that have access. No emails are sent when unpublishing
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <MReportPreview
                    reportData={report}
                    coverURL={coverURL}
                    hideAllActions
                  />
                </div>
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  {isSaving && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <Loader
                        type="Rings"
                        color="#5850ec"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                  {allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        {report.isPublished ? (
                          <p className="text-sm leading-5 text-gray-600 mr-4">
                            Report was published and e-mails will be sent
                            shortly{' '}
                            <span role="img" aria-label="rocket">
                              🚀
                            </span>
                          </p>
                        ) : (
                          <p className="text-sm leading-5 text-gray-600 mr-4">
                            Report was unpublished. It's only available to
                            Radicle admins{' '}
                            <span role="img" aria-label="road-works">
                              🚧
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {!isSaving && !allDone && (
                    <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                      {showConfirmation ? (
                        <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                          <p className="text-sm leading-5 text-gray-600 mr-4">
                            Are you sure?
                          </p>
                          <span className="inline-flex rounded-md shadow-sm">
                            <AButton
                              secondary
                              onClick={() => {
                                setShowConfirmation(false)
                              }}
                            >
                              Cancel
                            </AButton>
                          </span>
                          <span className=" inline-flex rounded-md shadow-sm ml-4">
                            <AButton onClick={handleSaveChanges}>
                              {report.isPublished
                                ? 'Yes, unpublish'
                                : 'Yes, publish'}
                            </AButton>
                          </span>
                        </div>
                      ) : (
                        <AButton
                          onClick={() => {
                            setShowConfirmation(true)
                          }}
                          className="py-2 px-4"
                        >
                          {report.isPublished
                            ? 'Unpublish'
                            : 'Publish and send emails'}
                        </AButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

export default function OEditReport({ organizationId }) {
  const { reportId } = useParams()

  const { data: report, loading: reportLoading } = useDocument(
    reportId ? `${CONST.frirebaseCollections.reports}/${reportId}` : null,

    { listen: 'true' }
  )

  const reportfileName = useMemo(() => {
    if (report) {
      return report.fileName
    }
    return 'Report name'
  }, [report])

  if (reportLoading || !report) {
    return (
      <div className="px-4 py-5 sm:px-6 flex flex-col flex-grow flex-1">
        <OLoader />
      </div>
    )
  }

  return (
    <>
      <div className=" px-4 pt-10  sm:px-6 ">
        <div className="">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="ml-4 mt-2 border-b border-gray-300 pb-4">
              <h3 className="text-lg md:text-3xl leading-6 font-bold text-hub-dark">
                <span className="text-hub-blue mr-2">Editing report:</span>{' '}
                {report ? report.name : 'Loading...'}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-4 pt-5 sm:px-6">
        <OManageUsersForReport
          key={reportId}
          reportId={reportId}
          organizationId={organizationId}
        />

        <ReportMetadaEdit report={report} reportLoading={reportLoading} />
        <ReportCoverEdit organizationId={organizationId} reportId={reportId} />
        <ReportFileEdit
          organizationId={organizationId}
          reportId={reportId}
          reportFileName={reportfileName}
        />
        <ReportPublishStatusEdit report={report} />
      </div>
    </>
  )
}

import React from 'react'
import { ReactSVG } from 'react-svg'
import AButton from '../atoms/AButton'
import copyToClipIcon from '../assets/copy-to-clipboard.svg'

export default ({ identifier }) => {
    const copyIdentifier = () => {
        navigator.clipboard.writeText(identifier)
    }
    const icon = (<ReactSVG src={copyToClipIcon} className="ml-2"/>)
    // Same as secondary button in AButton, except the padding.
    const buttonClassNames = `cursor-pointer inline-flex items-center
        px-1 py-2 border border-transparent text-sm leading-5 font-medium
        border border-gray-300 rounded-md text-hub-blue
        bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700
        focus:shadow-outline-indigo active:bg-indigo-700 active:text-white rounded-md`
    return (
        <div className="flex items-center">
          <span className="text-gray-500" style={{marginRight: '1em'}}>OrgID: {identifier}</span>
          <AButton icon={icon}
                   onClick={copyIdentifier}
                   overwriteStyles={true}
                   className={buttonClassNames}
          />
        </div>)
}

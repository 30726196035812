import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { useCollection, useDocument } from '@nandorojo/swr-firestore'
import Loader from 'react-loader-spinner'

import { fieldValueDelete, store } from '../services/Firebase'

import CONST from '../config/Constants'

import AButton from '../atoms/AButton'

/**
 *
 * ReportsSelection
 */
function ReportsSelection({
  reports,
  selectedReports = [],
  onAddToSelected,
  onRemoveFromSelected,
  disabled = false,
}) {
  return (
    <div className="flex flex-col px-4 sm:px-6 my-4 sm:my-5">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <fieldset className="" disabled={disabled}>
              <legend className="text-base leading-6 font-medium text-hub-dark">
                Available <span className=" text-indigo-700 ">reports</span>
              </legend>
              {reports &&
                reports.map((report) => (
                  <div key={report.id} className="mt-4">
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id={report.id}
                          type="checkbox"
                          checked={selectedReports.includes(report.id)}
                          className="form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                          onChange={(e) => {
                            e.target.checked
                              ? onAddToSelected(report.id)
                              : onRemoveFromSelected(report.id)
                          }}
                        />
                      </div>
                      <div className="flex-grow ml-3 text-sm leading-5 grid grid-cols-1 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 sm:col-gap-2 md:col-gap-3 lg:col-gap-4">
                        <div className="col-span-1 sm:col-span-3 md:col-span-4 lg:col-span-5">
                          <label
                            htmlFor={report.id}
                            className="font-medium text-gray-700 break-word"
                          >
                            {report.name}
                          </label>
                          <p className="text-gray-500">{report.authors}</p>
                        </div>
                        <div className="col-span-1 flex items-center justify-start md:justify-center mt-2 md:mt-0">
                          <>
                            {!report.isPublished && (
                              <span className="px-2 py-1 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full">
                                Draft
                              </span>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * EditUserForm
 */
function EditUserForm({ user, loading, updateUserFn }) {
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)

  const handleUserFormSubmit = useCallback(
    async (data) => {
      setIsSaving(true)
      await updateUserFn(data)
      setIsSaving(false)
      setAllDone(true)
    },
    [updateUserFn]
  )

  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: user,
  })
  const { isDirty, dirtyFields } = formState

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [reset, user])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        reset()
        setAllDone(false)
      }, 4000)
    }
  }, [allDone, reset])

  return (
    <form onSubmit={handleSubmit(handleUserFormSubmit)} method="POST">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Update user
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      First name
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      disabled={isSaving || allDone}
                      ref={register({ required: true })}
                      className={`${
                        dirtyFields.firstName
                          ? 'border-orange-300'
                          : 'border-gray-300'
                      } mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                      placeholder={loading ? 'Loading...' : null}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      disabled={isSaving || allDone}
                      ref={register({ required: true })}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder={loading ? 'Loading...' : null}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      disabled={isSaving || allDone}
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder={loading ? 'Loading...' : null}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                {isSaving && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <Loader
                      type="Rings"
                      color="#5850ec"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
                {allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                      <p className="text-sm leading-5 text-gray-600 mr-4">
                        User information updated{' '}
                        <span role="img" aria-label="strong">
                          💪
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                {user && !isSaving && !allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                    <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                      <p
                        className={`${
                          isDirty ? 'text-hub-blue' : 'text-gray-600'
                        } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                      >
                        {isDirty ? 'Save changes?' : 'No changes'}
                      </p>
                      <span className="inline-flex rounded-md shadow-sm">
                        <div
                          className={`${
                            !isDirty && 'opacity-50'
                          } cursor-pointer inline-flex justify-center py-2 px-4 border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white transition duration-150 ease-in-out`}
                          disabled={!isDirty}
                          onClick={() => {
                            reset()
                          }}
                        >
                          Cancel
                        </div>
                      </span>
                      <span className=" inline-flex rounded-md shadow-sm ml-4">
                        <button
                          type="submit"
                          disabled={!isDirty}
                          className={`${
                            !isDirty && 'opacity-50'
                          } inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out`}
                        >
                          Save changes
                        </button>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </form>
  )
}

/**
 *
 * EditUserOrganizationAccess
 */
function EditUserOrganizationAccess({
  user,
  organizationId,
  claims,
  onlyPublishedReports = true,
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [accessType, setAccessType] = useState(null)
  const [serverAccessType, setServerAccessType] = useState(null)
  const [selectedReports, setSelectedReports] = useState([])
  const [serverSelectedReports, setServerSelectedReports] = useState([])

  const whereQuery = useMemo(() => {
    let tmpWhereQuery = {
      where: ['organizationId', '==', organizationId],
      listen: true,
    }

    if (onlyPublishedReports) {
      tmpWhereQuery = {
        where: [
          ['organizationId', '==', organizationId],
          ['isPublished', '==', true],
        ],
        listen: true,
      }
    }

    return tmpWhereQuery
  }, [onlyPublishedReports, organizationId])

  const { data: reports } = useCollection(
    organizationId ? CONST.frirebaseCollections.reports : null,
    whereQuery
  )

  const { data: acl, update: aclUpdate } = useDocument(
    organizationId && user
      ? `${CONST.frirebaseCollections.acl}/${organizationId}--${user.id}`
      : null,
    { listen: true }
  )

  useEffect(() => {
    if (acl) {
      setAccessType(acl.reportsAccess)
      setServerAccessType(acl.reportsAccess)
      if (
        acl.reportsAccess === CONST.reportAccessTypes.shareselectedreports ||
        acl.reportsAccess === CONST.reportAccessTypes.selectedreports
      ) {
        const ids = Object.keys(acl.reportIds)
        setServerSelectedReports(ids)
        setSelectedReports(ids)
      } else {
        setServerSelectedReports([])
        setSelectedReports([])
      }
    }
  }, [acl])

  const canAddOrgAdmins = useMemo(() => {
    let tmpCanAddOrAdmins = false
    if (claims && organizationId) {
      if (claims.isRadicleAdmin) {
        tmpCanAddOrAdmins = true
      } else if (
        claims[organizationId]['a'] ===
        CONST.reportAccessTypes.organizationadmin
      ) {
        tmpCanAddOrAdmins = true
      }
    }
    console.log('tmpCanAddOrAdmins', tmpCanAddOrAdmins, claims)
    return tmpCanAddOrAdmins
  }, [claims, organizationId])

  // console.log('canAddOrgAdmins', canAddOrgAdmins)

  const handleAddReportToSelected = (reportId) => {
    const tmpSelected = [...selectedReports]
    tmpSelected.push(reportId)
    setSelectedReports(tmpSelected)
  }

  const handleRemoveReportFromSelected = (reportId) => {
    const tmpSelected = selectedReports.filter((id) => id !== reportId)
    setSelectedReports(tmpSelected)
  }

  const showReports = useMemo(() => {
    return (
      accessType === CONST.reportAccessTypes.selectedreports ||
      accessType === CONST.reportAccessTypes.shareselectedreports
    )
  }, [accessType])

  const [addedReportIds, removedReportIds, allChangedIds] = useMemo(() => {
    const reportIdsRemoved = serverSelectedReports.filter((userId) => {
      return !selectedReports.includes(userId)
    })
    const reportIdsAdded = selectedReports.filter((userId) => {
      return !serverSelectedReports.includes(userId)
    })
    const allIdsChanged = [...new Set([...reportIdsAdded, ...reportIdsRemoved])]
    return [reportIdsAdded, reportIdsRemoved, allIdsChanged]
  }, [selectedReports, serverSelectedReports])

  const isDirty = useMemo(() => {
    return serverAccessType !== accessType || allChangedIds.length !== 0
  }, [accessType, allChangedIds.length, serverAccessType])

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)
    const aclUpdateObj = {}
    const reportIdsAccessTypes = [
      CONST.reportAccessTypes.shareselectedreports,
      CONST.reportAccessTypes.selectedreports,
    ]

    if (reportIdsAccessTypes.includes(accessType)) {
      aclUpdateObj.reportIds = {}
      selectedReports.forEach((id) => {
        aclUpdateObj.reportIds[id] = true
      })
    } else {
      aclUpdateObj.reportIds = fieldValueDelete
    }

    aclUpdateObj.reportsAccess = accessType
    await aclUpdate(aclUpdateObj)
    setIsSaving(false)
    setAllDone(true)
  }, [accessType, aclUpdate, selectedReports])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Reports access
              </h3>
              {/* <p className="mt-1 text-sm leading-5 text-gray-600">
                  Organization admins will be able to invite new users to
                  organization.
                </p> */}
            </div>
          </div>
          <div className="md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset disabled={isSaving || allDone}>
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Access type
                  </legend>

                  <div className="mt-4">
                    <div className="flex items-center">
                      <input
                        id={CONST.reportAccessTypes.shareselectedreports}
                        value={CONST.reportAccessTypes.shareselectedreports}
                        checked={
                          accessType ===
                          CONST.reportAccessTypes.shareselectedreports
                        }
                        name="reportsAccess"
                        type="radio"
                        onChange={(e) => {
                          setAccessType(e.target.value)
                        }}
                        className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                      />
                      <label htmlFor="selectedreports" className="ml-3">
                        <span className="block text-sm leading-5 font-medium text-gray-700">
                          Download and share selected reports
                        </span>
                      </label>
                    </div>

                    <div className="mt-4 flex items-center">
                      <input
                        id={CONST.reportAccessTypes.shareallreports}
                        value={CONST.reportAccessTypes.shareallreports}
                        checked={
                          accessType === CONST.reportAccessTypes.shareallreports
                        }
                        name="reportsAccess"
                        type="radio"
                        onChange={(e) => {
                          setAccessType(e.target.value)
                        }}
                        className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                      />
                      <label htmlFor="allreports" className="ml-3">
                        <span className="block text-sm leading-5 font-medium text-gray-700">
                          Download and share all current and future reports
                        </span>
                        <p className="text-gray-500 text-xs">
                          Will be able invite other users to access all
                          available reports
                        </p>
                      </label>
                    </div>

                    {canAddOrgAdmins && (
                      <div className="mt-4  flex items-center">
                        <input
                          id={CONST.reportAccessTypes.organizationadmin}
                          value={CONST.reportAccessTypes.organizationadmin}
                          name="reportsAccess"
                          type="radio"
                          onChange={(e) => {
                            setAccessType(e.target.value)
                          }}
                          checked={
                            accessType ===
                            CONST.reportAccessTypes.organizationadmin
                          }
                          className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                        />
                        <label htmlFor="organizationadmin" className="ml-3">
                          <span className="block text-sm leading-5 font-medium text-gray-700">
                            Organization admin
                          </span>
                          <p className="text-gray-500 text-xs">
                            Access all reports, invite other admins and manage
                            user access across all reports
                          </p>
                        </label>
                      </div>
                    )}
                  </div>
                </fieldset>
              </div>
              {showReports && (
                <ReportsSelection
                  reports={reports}
                  selectedReports={selectedReports}
                  onAddToSelected={handleAddReportToSelected}
                  onRemoveFromSelected={handleRemoveReportFromSelected}
                  disabled={isSaving || allDone}
                />
              )}

              {isSaving && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                  <Loader type="Rings" color="#5850ec" height={40} width={40} />
                </div>
              )}
              {allDone && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                  <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                    <p className="text-sm leading-5 text-gray-600 mr-4">
                      User access was updated{' '}
                      <span role="img" aria-label="fire">
                        🔥
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {!isSaving && !allDone && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                    <p
                      className={`${
                        isDirty ? 'text-hub-blue' : 'text-gray-600'
                      } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                    >
                      {isDirty ? 'Save changes?' : 'No changes'}
                    </p>
                    <span className="inline-flex rounded-md shadow-sm">
                      <div
                        className={`${
                          !isDirty && 'opacity-50'
                        } cursor-pointer inline-flex justify-center py-2 px-4 border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white transition duration-150 ease-in-out`}
                        disabled={!isDirty}
                        onClick={() => {
                          setSelectedReports(serverSelectedReports)
                          setAccessType(serverAccessType)
                        }}
                      >
                        Cancel
                      </div>
                    </span>
                    <span className=" inline-flex rounded-md shadow-sm ml-4">
                      <button
                        onClick={handleSaveChanges}
                        disabled={!isDirty}
                        className={`${
                          !isDirty && 'opacity-50'
                        } inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out`}
                      >
                        Save changes
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </>
  )
}

/**
 *
 * RemoveUserForm
 */
function RemoveUserForm({ user, organizationId, redirectBackUrl }) {
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [shouldRedirectBack, setShouldRedirectBack] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleRemoveACL = useCallback(async () => {
    setIsSaving(true)
    const aclId = `${organizationId}--${user.id}`
    await store.collection(CONST.frirebaseCollections.acl).doc(aclId).delete()
    setIsSaving(false)
    setAllDone(true)
  }, [organizationId, user])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
        setShouldRedirectBack(true)
      }, 4000)
    }
  }, [allDone])

  if (shouldRedirectBack) {
    return <Redirect to={redirectBackUrl} />
  }

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Remove user from organization
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="text-base leading-6 font-medium text-hub-dark">
                  Remove user from this organization?
                  <span className="block text-sm leading-5 font-medium text-gray-700">
                    No information will be sent. This action removes access to
                    all reports from this organiation.
                  </span>
                </div>
              </div>
              <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                {isSaving && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <Loader
                      type="Rings"
                      color="#5850ec"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
                {allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                      <p className="text-sm leading-5 text-gray-600 mr-4">
                        User was removed
                      </p>
                    </div>
                  </div>
                )}

                {!isSaving && !allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                    {showConfirmation ? (
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Are you sure?
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            onClick={() => {
                              setShowConfirmation(false)
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton onClick={handleRemoveACL}>
                            Yes, remove user
                          </AButton>
                        </span>
                      </div>
                    ) : (
                      <AButton
                        onClick={() => {
                          setShowConfirmation(true)
                        }}
                      >
                        Remove user
                      </AButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </>
  )
}

export default function OEditUser({
  organizationId,
  onlyPublishedReports,
  claims,
  redirectBackUrl,
}) {
  const { userId } = useParams()

  const { data: user, loading: userLoading, update } = useDocument(
    userId ? `${CONST.frirebaseCollections.users}/${userId}` : null,
    { listen: true }
  )

  if (!userLoading & !user?.exists) {
    return <Redirect to={redirectBackUrl} />
  }

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <EditUserForm user={user} loading={userLoading} updateUserFn={update} />
      <EditUserOrganizationAccess
        user={user}
        organizationId={organizationId}
        claims={claims}
      />
      <RemoveUserForm
        user={user}
        organizationId={organizationId}
        redirectBackUrl={redirectBackUrl}
      />
    </div>
  )
}

import React, { useMemo, useEffect, useState } from 'react'
import { useDocument } from '@nandorojo/swr-firestore'

import { Link, useParams } from 'react-router-dom'
import MNoAccess from '../molecules/MNoAccess'
import OLoader from '../organisms/OLoader'
import MEndUserReportPreview from '../molecules/MEndUserReportPreview'
import MVideoPlayerModal from '../molecules/MVideoPlayerModal'

import CONST from '../config/Constants'
import { doesUserHaveAccessToReport } from '../utils/doesUserHaveAccess'

export default function PReportDetails({ claims }) {
  const { reportId } = useParams()
  const [videoUrl, setVideoUrl] = useState()
  const { data: reportData, loading: reportDataLoading } = useDocument(
    reportId ? `${CONST.frirebaseCollections.reports}/${reportId}` : null,

    { listen: 'true' }
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const doesUserHaveAccess = useMemo(() => {
    let doesHaveAccess = false
    if (claims && reportData && reportData.organizationId) {
      doesHaveAccess = doesUserHaveAccessToReport(claims, reportData)
    }
    return doesHaveAccess
  }, [claims, reportData])

  if (reportDataLoading) {
    return <OLoader />
  }

  if (!reportDataLoading && !doesUserHaveAccess) {
    return <MNoAccess />
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-1">
            <div className="-ml-4 -mt-2 flex-grow flex items-center flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2 flex flex-grow">
                <h3 className="text-lg md:text-2xl leading-6 font-medium text-hub-blue">
                  <Link to="/">Reports</Link>{' '}
                  <span className="text-xl mr-1 ml-1">{'>'}</span>
                  <span className="text-hub-dark">Report details</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-5 sm:px-6">
          <MEndUserReportPreview
            reportData={reportData}
            hideEdit
            hideLinkSharing
            getCoverUrl
            setVideoUrlFn={setVideoUrl}
          />
        </div>
      </div>
      <MVideoPlayerModal
        open={videoUrl ? true : false}
        setOpenFn={() => {
          setVideoUrl(null)
        }}
        videoUrl={videoUrl}
      />
    </>
  )
}

import React from 'react'
import { useDocument } from '@nandorojo/swr-firestore'

import { Route, Switch, useParams, useRouteMatch, Link } from 'react-router-dom'

import OLoader from '../organisms/OLoader'
import OOrganizationUsers from '../organisms/OOrganizationUsers'
import OAddUser from '../organisms/OAddUser'
import OEditUser from '../organisms/OEditUser'
import CONST from '../config/Constants'

export default function PEndUserManageUsers({ claims }) {
  const { organizationId } = useParams()
  const { path, url } = useRouteMatch()
  const addMatch = useRouteMatch(`${path}/add`)

  const {
    data: organizationData,
    loading: organizationDataLoading,
  } = useDocument(
    organizationId
      ? `${CONST.frirebaseCollections.organizations}/${organizationId}`
      : null,
    { listen: true }
  )

  if (organizationDataLoading) {
    return <OLoader />
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-1">
            <div className="-ml-4 -mt-2 flex-grow flex items-center flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2 flex flex-grow justify-between">
                <h3 className="text-lg md:text-2xl leading-6 font-medium text-hub-blue">
                  <Link to={`${url}`} className="">
                    {organizationData.name} users{' '}
                  </Link>

                  {addMatch && addMatch.isExact && (
                    <>
                      <span className="text-xl mr-1 ml-1">{'>'}</span>
                      <span className="text-hub-dark">Add</span>
                    </>
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <Switch>
          <Route exact path={`${path}/add`}>
            <OAddUser organizationId={organizationId} claims={claims} />
          </Route>

          <Route exact path={`${path}`}>
            <OOrganizationUsers
              organizationId={organizationId}
              userEditLinkPrefix={`${url}/edit`}
            />
          </Route>
          <Route
            exact
            path={`${path}/edit/:userId`}
            organizationId={organizationId}
            claims={claims}
          >
            <OEditUser
              organizationId={organizationId}
              claims={claims}
              redirectBackUrl={`${url}`}
            />
          </Route>
        </Switch>
      </div>
    </>
  )
}

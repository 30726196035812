import React, { useEffect, useRef, useState } from 'react'
import { Users } from 'react-feather'
import { Link } from 'react-router-dom'

import MEndUserReportPreview from '../molecules/MEndUserReportPreview'
import OLoader from '../organisms/OLoader'
import MVideoPlayerModal from '../molecules/MVideoPlayerModal'
import { store, documentId } from '../services/Firebase'
import CONST from '../config/Constants'

export default function PEndUserReports({ claims }) {
  let reportsRef = useRef([])
  let organizationsRef = useRef([])
  const [organizationReports, setOrganizationReports] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [organizationReportsLoading, setOrganizationReportsLoading] = useState(
    true
  )

  useEffect(() => {
    async function getOrganizationsAndReportsForCurrentUser() {
      const promises = []
      if (claims.isRadicleAdmin) {
        // If radicle admin, get all published reports for all organizations
        const orgPromise = store
          .collection(CONST.frirebaseCollections.organizations)
          .get()
          .then((orgs) => {
            organizationsRef.current = orgs.docs
          })
        promises.push(orgPromise)

        const reportsPromise = store
          .collection(CONST.frirebaseCollections.reports)
          .where('isPublished', '==', true)
          .get()
          .then((reports) => {
            reportsRef.current = reports.docs
          })
        promises.push(reportsPromise)
      } else {
        // If NOT radicle admin
        // Get organizations from keys
        const organizationKeys = Object.keys(claims)
        const orgPromise = store
          .collection(CONST.frirebaseCollections.organizations)
          .where(documentId, 'in', organizationKeys)
          .get()
          .then((orgs) => {
            organizationsRef.current = orgs.docs
          })
        promises.push(orgPromise)

        for (let index = 0; index < organizationKeys.length; index++) {
          const orgKey = organizationKeys[index]
          const access = claims[orgKey]['a']
          if (
            access === CONST.reportAccessTypes.organizationadmin ||
            access === CONST.reportAccessTypes.allreports ||
            access === CONST.reportAccessTypes.shareallreports
          ) {
            // If NOT radicle admin but all reports or org admin
            // Get all published reports from organizations from claims
            const reportsPromise = store
              .collection(CONST.frirebaseCollections.reports)
              .where('isPublished', '==', true)
              .where('organizationId', '==', orgKey)
              .get()
              .then((reports) => {
                reportsRef.current = reportsRef.current.concat(reports.docs)
              })
            promises.push(reportsPromise)
          } else if (
            access === CONST.reportAccessTypes.selectedreports ||
            access === CONST.reportAccessTypes.shareselectedreports
          ) {
            // If NOT radicle admin and selected reports
            // Get only selected reports
            const reportIds = claims[orgKey]['rids']
            if (reportIds.length > 0) {
              reportIds.forEach((reportId) => {
                const reportsPromise = store
                  .collection(CONST.frirebaseCollections.reports)
                  .doc(reportId)
                  .get()
                  .then((report) => {
                    if (report.exists && report.data()['isPublished']) {
                      reportsRef.current.push(report)
                    }
                  })
                promises.push(reportsPromise)
              })
            }
          }
        }
      }
      await Promise.all(promises)
      let orgReports = []

      organizationsRef.current.forEach((org) => {
        orgReports.push({
          organization: { id: org.id, ...org.data() },
          reports: reportsRef.current
            .filter((rep) => {
              return rep.data()['organizationId'] === org.id
            })
            .map((rep) => {
              return { id: rep.id, ...rep.data() }
            })
            .sort((a, b) => {
              return b.createdAt.seconds - a.createdAt.seconds
            }),
          isAdmin:
            claims.isRadicleAdmin ||
            claims[org.id]['a'] === CONST.reportAccessTypes.organizationadmin
              ? true
              : false,
        })
      })

      setOrganizationReports(orgReports)
      setOrganizationReportsLoading(false)
    }

    if (claims) {
      getOrganizationsAndReportsForCurrentUser()
    }
  }, [claims, organizationsRef, reportsRef])

  if (organizationReportsLoading) {
    return (
      <div className="px-4 py-5 sm:px-6 flex flex-col flex-grow flex-1">
        <OLoader />
      </div>
    )
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        {organizationReports.map((obj, index) => (
          <div key={obj.organization.id}>
            <div className=" px-4 py-5 sm:px-6">
              <div className="">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                  <div className="ml-4 mt-2 flex items-center justify-center">
                    <h3 className="text-lg md:text-3xl leading-6 font-bold text-hub-dark">
                      {obj.organization.name} reports
                    </h3>
                    {obj.isAdmin && (
                      <div className="ml-4 flex flex-grow-0 mx-auto">
                        <Link
                          to={`/users-for/${obj.organization.id}`}
                          className="cursor-pointer flex flex-row justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-hub-dark-gray  hover:bg-gray-100 focus:outline-none "
                        >
                          <Users className="text-hub-dark-gray w-3 h-3 mr-2" />
                          <span>Manage users</span>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {obj.reports &&
                  obj.reports.map((report, index) => (
                    <MEndUserReportPreview
                      key={report.id}
                      getCoverUrl
                      reportData={report}
                      setVideoUrlFn={setVideoUrl}
                    />
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <MVideoPlayerModal
        open={videoUrl ? true : false}
        setOpenFn={() => {
          setVideoUrl(null)
        }}
        videoUrl={videoUrl}
      />
    </>
  )
}

const constants = {
  frirebaseCollections: {
    users: 'users',
    organizations: 'organizations',
    reports: 'reports',
    acl: 'acl',
    reportDownloads: 'reportDownloads',
  },
  reportAccessTypes: {
    organizationadmin: 'organizationadmin',
    allreports: 'allreports',
    shareallreports: 'shareallreports',
    selectedreports: 'selectedreports',
    shareselectedreports: 'shareselectedreports',
  },
  colors: {
    'hub-dark': '#201F52',
    'hub-blue': '#4340DE',
    'hub-dark-gray': '#5D5D6F',
    'hub-light-gray': '#F8F9FB',
    'hub-border-gray': '#EBEBEB',
    'hub-honey-flower': '#5a1e5f',
  },
  reportTypes: {
    debrief: 'Debrief',
    ranger: 'Ranger',
    radar: 'Radar',
    dialogue: 'Dialogue',
  },
}

const initialAclsToUsers = {}
initialAclsToUsers[constants.reportAccessTypes.allreports] = []
initialAclsToUsers[constants.reportAccessTypes.selectedreports] = []
initialAclsToUsers[constants.reportAccessTypes.organizationadmin] = []
initialAclsToUsers[constants.reportAccessTypes.shareallreports] = []
initialAclsToUsers[constants.reportAccessTypes.shareselectedreports] = []

module.exports = constants
module.exports.initialAclsToUsers = initialAclsToUsers

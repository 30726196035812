import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'

import { auth, functions } from '../services/Firebase'
import Config from '../config/Config'

import AButton from '../atoms/AButton'
import RocketManImage from '../assets/rocket-man.svg'
import LogoWhite from '../assets/logo-white-bg.svg'

import { logEvent, EVENTS } from '../services/Analytics'

const remoteCheckIfUserExists = functions.httpsCallable('checkIfUserExists')

export default function PSignIn() {
  const { register, handleSubmit } = useForm()
  const [isWorking, setIsWorking] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [errorMsg, setErrorMsg] = useState()

  const sendEmailSignInLink = async (data) => {
    try {
      setIsWorking(true)
      setErrorMsg(false)
      const result = await remoteCheckIfUserExists({ email: data.email })
      const { userExists } = result.data
      if (userExists) {
        const actionCodeSettings = {
          url: `${Config.domain}/sign-in-with-email-link/`,
          handleCodeInApp: true,
        }

        await auth.sendSignInLinkToEmail(data.email, actionCodeSettings)

        window.localStorage.setItem('emailForSignIn', data.email)
        logEvent(EVENTS.LOGIN.EMAIL_SENT)
        setIsEmailSent(true)

        setIsWorking(false)
      } else {
        logEvent(EVENTS.LOGIN.NO_EMAIL)
        setErrorMsg(
          'There is no user with that email. Please make sure the email is correct.'
        )
        setIsWorking(false)
      }
    } catch (err) {
      setErrorMsg(err.message)
      setIsWorking(false)
    }
  }

  return (
    <div className="min-h-screen bg-white sm:bg-hub-dark flex flex-col sm:justify-center">
      <div className="sm:mx-auto">
        <div className="bg-white flex flex-col sm:flex-row items-center justify-center sm:mx-8 relative">
          <ReactSVG
            src={LogoWhite}
            className="absolute sm:bottom-4 sm:right-4 self-start hidden sm:block"
          />

          <div className="py-24 sm:py-12 mx-auto px-4 sm:pl-16 sm:pr-0 sm:-mr-4 sm:max-w-sm sm:flex-shrink-0 relative">
            <ReactSVG
              src={LogoWhite}
              className="absolute top-4 left-0 self-start sm:hidden"
            />

            <form onSubmit={handleSubmit(sendEmailSignInLink)} method="POST">
              <h3 className="text-4xl break-words font-semibold mb-9 leading-snug text-hub-dark">
                Generate your sign-in link
              </h3>

              <div className="mt-1 rounded-md">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  disabled={isEmailSent}
                  required
                  ref={register({ required: true })}
                  className="appearance-none block w-full px-3 py-2 border bg-hub-light-gray border-hub-border-gray rounded-sm placeholder-hub-dark-gray focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>

              {!isEmailSent && (
                <div className="mt-6">
                  <AButton
                    type="submit"
                    loadingIcon={null}
                    isLoading={isWorking}
                    loadingText="Working..."
                    fullBorderRadius
                    // className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    // overwriteStyles
                  >
                    Generate
                  </AButton>
                  <div className="mt-6">
                    <div className="mt-6 flex flex-1 flex-grow">
                      <p className=" text-xs text-hub-dark-gray mx-auto">
                        We will send a sign-in link to this email. Just click on
                        that link to sign-in.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </form>
            {errorMsg && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm leading-5">
                    <span className="px-2 bg-white text-gray-500">Error</span>
                  </div>
                </div>

                <div className="mt-6 flex flex-1 flex-grow">
                  <p className="text-red-600 text-sm mx-auto">{errorMsg}</p>
                </div>
              </div>
            )}
            {isEmailSent && (
              <div className="mt-4 flex flex-1 flex-grow flex-col items-start justify-start">
                <p className="text-sm font-medium leading-5 text-gray-700">
                  Link was sent
                </p>
                <p className="mt-1 text-xs leading-5 text-hub-dark-gray">
                  Check your inbox and click the link to sign-in
                </p>
              </div>
            )}
          </div>

          <ReactSVG
            src={RocketManImage}
            className="flex-shrink flex-grow-0 hidden sm:flex w-full h-auto"
            beforeInjection={(svg) => {
              svg.classList.add('w-full')
              svg.classList.add('h-auto')
            }}
          />
        </div>
      </div>
    </div>
  )
}

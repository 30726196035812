import React from 'react'
import { useDocument } from '@nandorojo/swr-firestore'

import {
  Link,
  NavLink,
  Route,
  Switch,
  Redirect,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import OLoader from '../organisms/OLoader'
import OOrganizationReports from '../organisms/OOrganizationReports'
import OOrganizationUsers from '../organisms/OOrganizationUsers'
import OAddReport from '../organisms/OAddReport'
import OEditReport from '../organisms/OEditReport'
import OAddUser from '../organisms/OAddUser'
import OEditUser from '../organisms/OEditUser'
import MOrganizationId from '../molecules/MOrganizationId'
import CONST from '../config/Constants'

export default function POrganizationDetails({ claims }) {
  const { path, url } = useRouteMatch()
  const { organizationId } = useParams()

  const {
    data: organizationData,
    loading: organizationDataLoading,
  } = useDocument(
    organizationId
      ? `${CONST.frirebaseCollections.organizations}/${organizationId}`
      : null,
    { listen: true }
  )

  if (organizationDataLoading) {
    return <OLoader />
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-1">
            <div className="-ml-4 -mt-2 flex-grow flex items-center flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2 flex flex-grow justify-between">
                <h3 className="text-lg md:text-2xl leading-6 font-medium text-hub-blue">
                  <Link to="/organizations">Organizations</Link>{' '}
                  <span className="text-xl mr-1 ml-1">{'>'}</span>
                  <span className="text-hub-dark">{organizationData.name}</span>
                </h3>
                <div className="hidden lg:block">
                  <MOrganizationId identifier={organizationId} />
                </div>
              </div>
              {/* <div className="ml-4 mt-2 flex-shrink-0 self-end">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                    onClick={() => {
                      alert('Add report')
                    }}
                  >
                    Add Report
                  </button>
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="px-4 pt-2 sm:px-6">
          <div className="sm:hidden">
            <select
              aria-label="Selected tab"
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
              defaultValue="Reports"
            >
              <option>Reports</option>
              <option>Users</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">
                <NavLink
                  to={`${url}/reports`}
                  className="whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                  activeClassName="border-indigo-500  text-hub-blue focus:outline-none focus:text-indigo-800 focus:border-indigo-700"
                  aria-current="page"
                >
                  Reports
                </NavLink>
                <NavLink
                  to={`${url}/users`}
                  className="whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
                  activeClassName="border-indigo-500  text-hub-blue focus:outline-none focus:text-indigo-800 focus:border-indigo-700"
                >
                  Users
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/reports`} />
          <Route exact path={`${path}/reports/add`}>
            <OAddReport organizationId={organizationId} />
          </Route>
          <Route exact path={`${path}/reports/:reportId/edit`}>
            <OEditReport organizationId={organizationId} />
          </Route>
          <Route exact path={`${path}/reports`}>
            <OOrganizationReports organizationId={organizationId} />
          </Route>
          <Route exact path={`${path}/users/add`}>
            <OAddUser organizationId={organizationId} claims={claims} />
          </Route>
          <Route exact path={`${path}/users`}>
            <OOrganizationUsers
              organizationId={organizationId}
              userEditLinkPrefix={`${url}/users/edit`}
            />
          </Route>
          <Route
            exact
            path={`${path}/users/edit/:userId`}
            organizationId={organizationId}
            claims={claims}
          >
            <OEditUser
              organizationId={organizationId}
              claims={claims}
              redirectBackUrl={`${url}/users`}
            />
          </Route>
        </Switch>
      </div>
    </>
  )
}

import React, { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

import AButton from '../atoms/AButton'
import { store, functions } from '../services/Firebase'
import CONST from '../config/Constants'
const remoteAddUser = functions.httpsCallable('addUser')

export default function OAddUserLimitedAccess({ organizationId, reportId }) {
  const [accessType, setAccessType] = useState(
    CONST.reportAccessTypes.shareselectedreports
  )
  const [userData, setUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleUserFormSubmit = (data) => {
    setUserData(data)
    setShowConfirmation(true)
  }

  const saveUserData = useCallback(async () => {
    setIsSaving(true)
    //rewrite remoteAddUser to take ACL as a param
    const result = await remoteAddUser(userData)
    const { userId } = result.data
    const aclId = `${organizationId}--${userId}`
    const aclObject = {
      organizationId,
      userId: userId,
      reportsAccess: accessType,
      reportIds: { [reportId]: true },
    }

    await store
      .collection(CONST.frirebaseCollections.acl)
      .doc(aclId)
      .set(aclObject, { merge: true })
    setIsSaving(false)
    setAllDone(true)
  }, [userData, organizationId, accessType, reportId])

  const { register, handleSubmit, reset } = useForm({
    defaultValues: userData,
  })

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        reset()
        setShowConfirmation(false)
        setAllDone(false)
      }, 4000)
    }
  }, [allDone, reset])

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <form onSubmit={handleSubmit(handleUserFormSubmit)} method="POST">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-hub-dark">
                  Invite user
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-600">
                  Users sign-in using special sin-in links sent to their e-mail.
                  Please double check for any typos in the e-mail.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        First name
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        disabled={isSaving || allDone}
                        ref={register({ required: true })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        id="lastName"
                        name="lastName"
                        disabled={isSaving || allDone}
                        ref={register({ required: true })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        id="email"
                        name="email"
                        disabled={isSaving || allDone}
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                          },
                        })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                </div>
                {isSaving && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <Loader
                      type="Rings"
                      color="#5850ec"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
                {allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                      <p className="text-sm leading-5 text-gray-600 mr-4">
                        All done{' '}
                        <span role="img" aria-label="confetti">
                          🎉
                        </span>
                      </p>{' '}
                      {/* <Link
                        className="underline text-sm leading-5  text-hub-blue"
                        to={`/organizations/${organizationId}/users`}
                      >
                        Back to users
                      </Link> */}
                    </div>
                  </div>
                )}

                {!isSaving && !allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                    {showConfirmation ? (
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Are you sure?
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            onClick={() => {
                              setShowConfirmation(false)
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton onClick={saveUserData}>
                            Yes, save and invite
                          </AButton>
                        </span>
                      </div>
                    ) : (
                      <AButton type="submit" className="py-2 px-4">
                        Save and send invitation
                      </AButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useCollection, useDocument } from '@nandorojo/swr-firestore'

import Loader from 'react-loader-spinner'

import {
  store,
  fieldValueDelete,
  useFirebaseCollectionDataFromIds,
} from '../services/Firebase'

import CONST, { initialAclsToUsers } from '../config/Constants'

import MUserWithCheckboxListItem from '../molecules/MUserWithCheckboxListItem'

export default function OManageUsersForReport({ reportId, organizationId }) {
  const [willBeNoUsers, setWillBeNoUsers] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [allUserIds, setAllUserIds] = useState(null)

  const { data: acls, loading: aclsLoading } = useCollection(
    CONST.frirebaseCollections.acl,
    { where: ['organizationId', '==', organizationId], listen: true }
  )

  const { data: reportDownloads } = useDocument(
    `${CONST.frirebaseCollections.reportDownloads}/${reportId}`,
    {
      listen: true,
    }
  )

  const [users] = useFirebaseCollectionDataFromIds(
    CONST.frirebaseCollections.users,
    allUserIds
  )

  useEffect(() => {
    if (!aclsLoading && acls) {
      if (acls.length > 0) {
        const userIds = acls.map((a) => {
          return a.userId
        })
        setAllUserIds(userIds)
      } else {
        setWillBeNoUsers(true)
      }
    }
  }, [acls, aclsLoading, organizationId])

  const [usersACLS, serverSavedSelectedUsers] = useMemo(() => {
    if (!aclsLoading && acls) {
      const tmpUsersACLS = {}
      const tmpServerSavedSelectedUsers = []
      acls.forEach((acl) => {
        tmpUsersACLS[acl.userId] = acl
        if (
          acl.reportsAccess === CONST.reportAccessTypes.shareselectedreports &&
          Object.keys(acl.reportIds).includes(reportId)
        ) {
          tmpServerSavedSelectedUsers.push(acl.userId)
        }
      })
      return [tmpUsersACLS, tmpServerSavedSelectedUsers]
    }
    return [{}, []]
  }, [acls, aclsLoading, reportId])

  const usersToDownloads = useMemo(() => {
    let tmpUsersToDownloads = {}
    if (users && reportDownloads) {
      users.forEach((user) => {
        tmpUsersToDownloads[user.id] = reportDownloads.users[user.id] || 0
      })
    } else if (users) {
      users.forEach((user) => {
        tmpUsersToDownloads[user.id] = '...'
      })
    }
    return tmpUsersToDownloads
  }, [users, reportDownloads])

  useEffect(() => {
    setSelectedUsers(serverSavedSelectedUsers)
  }, [serverSavedSelectedUsers])

  const aclUsers = useMemo(() => {
    if (!willBeNoUsers && users) {
      const tmpAclsToUsers = JSON.parse(JSON.stringify(initialAclsToUsers))
      users.forEach((user) => {
        const accessType = usersACLS[user.id]['reportsAccess']
        tmpAclsToUsers[accessType].push(user)
      })

      return tmpAclsToUsers
    }
    return initialAclsToUsers
  }, [users, usersACLS, willBeNoUsers])

  const handleAddUserToSelected = (userId) => {
    const tmpSelected = [...selectedUsers]
    tmpSelected.push(userId)
    setSelectedUsers(tmpSelected)
  }

  const handleRemoveUserFromSelected = (userId) => {
    const tmpSelected = selectedUsers.filter((id) => id !== userId)
    setSelectedUsers(tmpSelected)
  }

  const [addedUserIds, removedUserIds, allChangedIds] = useMemo(() => {
    const userIdsRemoved = serverSavedSelectedUsers.filter((userId) => {
      return !selectedUsers.includes(userId)
    })
    const userIdsAdded = selectedUsers.filter((userId) => {
      return !serverSavedSelectedUsers.includes(userId)
    })
    const allIdsChanged = [...new Set([...userIdsAdded, ...userIdsRemoved])]
    return [userIdsAdded, userIdsRemoved, allIdsChanged]
  }, [selectedUsers, serverSavedSelectedUsers])

  const isDirty = useMemo(() => {
    return allChangedIds.length !== 0
  }, [allChangedIds])

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)

    const promises = []
    removedUserIds.forEach((userId) => {
      const promise = store
        .collection(CONST.frirebaseCollections.acl)
        .doc(usersACLS[userId].id)
        .update({
          [`reportIds.${reportId}`]: fieldValueDelete,
        })
      promises.push(promise)
    })
    addedUserIds.forEach((userId) => {
      const promise = store
        .collection(CONST.frirebaseCollections.acl)
        .doc(usersACLS[userId].id)
        .update({
          [`reportIds.${reportId}`]: true,
        })
      promises.push(promise)
    })
    await Promise.all(promises)
    setIsSaving(false)
    setAllDone(true)
  }, [addedUserIds, removedUserIds, reportId, usersACLS])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        setAllDone(false)
      }, 4000)
    }
  }, [allDone])

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                User access
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                Organization admins and users with access to all reports are
                included by default.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Organization{' '}
                    <span className=" text-indigo-700 ">admins</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.organizationadmin].map(
                    (user) => (
                      <MUserWithCheckboxListItem
                        key={user.id}
                        user={user}
                        checked
                        disabled
                        downloadsCount={usersToDownloads[user.id]}
                      />
                    )
                  )}
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download and share{' '}
                    <span className=" text-indigo-700 ">all reports</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.shareallreports].map(
                    (user) => (
                      <MUserWithCheckboxListItem
                        key={user.id}
                        user={user}
                        checked
                        disabled
                        downloadsCount={usersToDownloads[user.id]}
                      />
                    )
                  )}
                </fieldset>
                {/* <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download{' '}
                    <span className=" text-indigo-700 ">all reports</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.allreports].map((user) => (
                    <MUserWithCheckboxListItem
                      key={user.id}
                      user={user}
                      checked
                      disabled
                      downloadsCount={usersToDownloads[user.id]}
                    />
                  ))}
                </fieldset> */}
                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download and share{' '}
                    <span className=" text-indigo-700 ">this report</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.shareselectedreports].map(
                    (user) => (
                      <MUserWithCheckboxListItem
                        key={user.id}
                        user={user}
                        checked={selectedUsers.includes(user.id)}
                        disabled={false}
                        set
                        downloadsCount={usersToDownloads[user.id]}
                        isDirty={allChangedIds.includes(user.id)}
                        onChange={(e) => {
                          e.target.checked
                            ? handleAddUserToSelected(user.id)
                            : handleRemoveUserFromSelected(user.id)
                        }}
                      />
                    )
                  )}
                </fieldset>
                {/* <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download{' '}
                    <span className=" text-indigo-700 ">this report</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.selectedreports].map(
                    (user) => (
                      <MUserWithCheckboxListItem
                        key={user.id}
                        user={user}
                        checked={selectedUsers.includes(user.id)}
                        disabled={false}
                        downloadsCount={usersToDownloads[user.id]}
                        isDirty={allChangedIds.includes(user.id)}
                        onChange={(e) => {
                          e.target.checked
                            ? handleAddUserToSelected(user.id)
                            : handleRemoveUserFromSelected(user.id)
                        }}
                      />
                    )
                  )}
                </fieldset> */}
              </div>
              {isSaving && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                  <Loader type="Rings" color="#5850ec" height={40} width={40} />
                </div>
              )}
              {allDone && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                  <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                    <p className="text-sm leading-5 text-gray-600 mr-4">
                      User access was updated{' '}
                      <span role="img" aria-label="fire">
                        🔥
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {!isSaving && !allDone && (
                <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                  <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                    <p
                      className={`${
                        isDirty ? 'text-hub-blue' : 'text-gray-600'
                      } text-sm leading-5  mr-4 transition duration-150 ease-in-out`}
                    >
                      {isDirty ? 'Save changes?' : 'No changes'}
                    </p>
                    <span className="inline-flex rounded-md shadow-sm">
                      <div
                        className={`${
                          !isDirty && 'opacity-50'
                        } cursor-pointer inline-flex justify-center py-2 px-4 border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white transition duration-150 ease-in-out`}
                        disabled={!isDirty}
                        onClick={() => {
                          setSelectedUsers(serverSavedSelectedUsers)
                        }}
                      >
                        Cancel
                      </div>
                    </span>
                    <span className=" inline-flex rounded-md shadow-sm ml-4">
                      <button
                        onClick={handleSaveChanges}
                        disabled={!isDirty}
                        className={`${
                          !isDirty && 'opacity-50'
                        } inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out`}
                      >
                        Save changes
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useRef, useState } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import { Fuego } from '@nandorojo/swr-firestore'
import CONFIG from '../config/Config'

const firebaseConfig = CONFIG.firebase

const Firebase = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app()

export const auth = Firebase.auth(firebase.auth.Auth.Persistence.LOCAL)

export const store = Firebase.firestore()
export const files = Firebase.storage()
export const functions = Firebase.functions()

export const documentId = firebase.firestore.FieldPath.documentId()
export const fieldValueDelete = firebase.firestore.FieldValue.delete()
export const fieldValueServerTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const firestoreTimestamp = firebase.firestore.Timestamp
export const fuego = new Fuego(firebaseConfig)

export function useFirebaseCollectionDataFromIds(collectionName, ids) {
  const objectsRef = useRef({})
  const [objects, setObjects] = useState([])
  const [objectsLoading, setObjectsLoading] = useState(true)

  useEffect(() => {
    async function getObjects() {
      setObjectsLoading(true)
      const promises = []
      ids.forEach((id) => {
        if (!objectsRef.current[id]) {
          const promise = store
            .collection(collectionName)
            .doc(id)
            .get()
            .then((obj) => {
              if (obj.exists) {
                objectsRef.current[id] = { ...obj.data(), id: obj.id }
              }
            })
          promises.push(promise)
        }
      })
      await Promise.all(promises)
      setObjects(Object.values(objectsRef.current))
      setObjectsLoading(false)
    }
    if (!collectionName || !ids) {
      setObjects([])
      setObjectsLoading(false)
    } else {
      getObjects()
    }
  }, [collectionName, ids])
  return [objects, objectsLoading]
}

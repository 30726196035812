import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { FileText } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { useCollection } from '@nandorojo/swr-firestore'
import Loader from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import MReportPreview from '../molecules/MReportPreview'
import OLoader from './OLoader'
import {
  store,
  files,
  auth,
  fieldValueServerTimestamp,
  useFirebaseCollectionDataFromIds,
  firestoreTimestamp,
} from '../services/Firebase'

import CONST, { initialAclsToUsers } from '../config/Constants'

const SAVING_STATUS = {
  awaiting: 'awaiting',
  active: 'active',
  done: 'done',
}
function StepIndicator({
  stepNumber,
  stepLabel,
  isActive,
  isFinished,
  isLast,
  onClick,
}) {
  if (isActive) {
    return (
      <li
        className="relative md:flex-1 md:flex cursor-pointer"
        onClick={onClick}
      >
        <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
          <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
            <p className="text-hub-blue">{stepNumber}</p>
          </div>
          <p className="text-sm leading-5 font-medium text-hub-blue">
            {stepLabel}
          </p>
        </div>

        <div className="hidden md:block absolute top-0 right-0 h-full w-5">
          {!isLast && (
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </li>
    )
  }
  if (isFinished) {
    return (
      <li
        className="relative md:flex-1 md:flex cursor-pointer"
        onClick={onClick}
      >
        <div href="#" className="group flex items-center w-full">
          <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
            <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800 transition ease-in-out duration-150">
              <svg
                className="w-6 h-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="text-sm leading-5 font-medium text-hub-dark">
              {stepLabel}
            </p>
          </div>
        </div>

        <div className="hidden md:block absolute top-0 right-0 h-full w-5">
          <svg
            className="h-full w-full text-gray-300"
            viewBox="0 0 22 80"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0 -2L20 40L0 82"
              vectorEffect="non-scaling-stroke"
              stroke="currentcolor"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </li>
    )
  }
  return (
    <li className="relative md:flex-1 md:flex cursor-pointer" onClick={onClick}>
      <div className="group flex items-center">
        <div className="px-6 py-4 flex items-center text-sm leading-5 font-medium space-x-4">
          <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400 transition ease-in-out duration-150">
            <span className="text-gray-500 group-hover:text-hub-dark transition ease-in-out duration-150">
              {stepNumber}
            </span>
          </div>
          <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-hub-dark transition ease-in-out duration-150">
            {stepLabel}
          </p>
        </div>
      </div>
      <div className="hidden md:block absolute top-0 right-0 h-full w-5">
        {!isLast && (
          <svg
            className="h-full w-full text-gray-300"
            viewBox="0 0 22 80"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M0 -2L20 40L0 82"
              vectorEffect="non-scaling-stroke"
              stroke="currentcolor"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </li>
  )
}

function DroppableFileContainer({
  onFileAccepted,
  isSaving,
  accept,
  fileExtensionLabel,
  highlightMissing = false,
}) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        onFileAccepted(file)
      }
    },
    [onFileAccepted]
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    accept: accept,
    onDrop: onDrop,
  })

  return (
    <>
      {isSaving ? (
        <OLoader height={50} width={50} />
      ) : (
        <div
          {...getRootProps()}
          className={`${
            highlightMissing ? 'border-red-300' : 'border-gray-300'
          } mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md`}
        >
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input {...getInputProps()} />
            <p className="mt-1 text-sm text-gray-600">
              {isDragActive && isDragAccept ? (
                <>
                  <span className="font-medium text-hub-blue hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1">
                    Drop file here
                  </span>
                </>
              ) : (
                <>
                  <span className="font-medium text-hub-blue hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out mr-1">
                    Upload a file
                  </span>
                  <span>or drop here</span>
                </>
              )}
            </p>
            <p className="mt-1 text-xs text-gray-500">{fileExtensionLabel}</p>
          </div>
        </div>
      )}
    </>
  )
}

function CoverPreview({ file, onRemoveFile }) {
  const urledFile = URL.createObjectURL(file)
  return (
    <div className="mt-2 flex flex-col justify-center items-center content-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
      <div className="flex flex-col flex-grow justify-center items-center content-center">
        <img src={urledFile} alt="Cover" className="rounded-md"></img>
      </div>
      <div className="mt-2 flex flex-col flex-grow-0">
        <div
          onClick={onRemoveFile}
          className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
        >
          Change cover
        </div>
      </div>
    </div>
  )
}

function ReportPreview({ file, onRemoveFile }) {
  return (
    <div className="mt-2 flex flex-col flex-grow justify-center items-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
      <div className="flex flex-col flex-grow justify-center items-center content-center">
        <FileText className="mt-2 h-12 w-12 text-gray-400" />
        <p className="mt-1 mb-4 text-sm text-gray-600 break-word text-center ">
          {file.name}
        </p>
      </div>
      <div className="mt-2 flex flex-col flex-grow-0">
        <div
          onClick={onRemoveFile}
          className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
        >
          Change report PDF
        </div>
      </div>
    </div>
  )
}

function UsersSelection({
  aclUsers,
  selectedUsers = [],
  onAddToSelected,
  onRemoveFromSelected,
  onFormSubmitted,
}) {
  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6"></div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                User access
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                Organization admins and users with access to all reports are
                included by default.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <fieldset>
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Organization{' '}
                    <span className=" text-indigo-700 ">admins</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.organizationadmin].map(
                    (user) => (
                      <div key={user.id} className="mt-4">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={user.id}
                              checked
                              disabled
                              type="checkbox"
                              className="form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out opacity-50"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-5">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {user.firstName} {user.lastName}
                            </label>
                            <p className="text-gray-500">{user.email}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download and share{' '}
                    <span className=" text-indigo-700 ">all reports</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.shareallreports].map(
                    (user) => (
                      <div key={user.id} className="mt-4">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="comments"
                              type="checkbox"
                              checked
                              disabled
                              className="form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out opacity-50"
                            />
                          </div>
                          <div className="ml-3 text-sm leading-5">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {user.firstName} {user.lastName}
                            </label>
                            <p className="text-gray-500">{user.email}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="text-base leading-6 font-medium text-hub-dark">
                    Download and share{' '}
                    <span className=" text-indigo-700 ">this report</span>
                  </legend>
                  {aclUsers[CONST.reportAccessTypes.shareselectedreports].map(
                    (user) => (
                      <div key={user.id} className="mt-4">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="comments"
                              type="checkbox"
                              checked={selectedUsers.includes(user.id)}
                              className="form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                              onChange={(e) => {
                                e.target.checked
                                  ? onAddToSelected(user.id)
                                  : onRemoveFromSelected(user.id)
                              }}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-5">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {user.firstName} {user.lastName}
                            </label>
                            <p className="text-gray-500">{user.email}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </fieldset>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={onFormSubmitted}
                  className="cursor-pointer py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ReportForm({
  reportData,
  coverFile: coverFileProvided,
  reportFile: reportFileProvided,
  onFormSubmitted,
}) {
  const [coverFile, setCoverFile] = useState(coverFileProvided)
  const [reportFile, setReportFile] = useState(reportFileProvided)
  const [triedToMoveForward, setTriedToMoveForward] = useState(false)

  const {
    register: reportRegister,
    handleSubmit: reportHandleSubmit,
    setValue: reportSetValue,
    watch,
  } = useForm({ defaultValues: reportData })

  const onCoverUploaded = (file) => {
    setCoverFile(file)
  }

  const onReportUploaded = (file) => {
    const suggestedName = file.name.replace('.pdf', '')
    reportSetValue('fileName', suggestedName)
    setReportFile(file)
  }

  const handleRemoveReportFile = () => {
    setReportFile(null)
  }

  const handleRemoveCoverFile = () => {
    setCoverFile(null)
  }

  const onSaveReportData = async (data) => {
    setTriedToMoveForward(true)

    if (reportFile && coverFile) {
      onFormSubmitted(data, coverFile, reportFile)
    }
  }

  // useEffect(() => {
  //   reportSetValue('createdAt', new Date())
  //   console.log('Initial set created at')
  // }, [reportSetValue])

  const watchCreatedAt = watch('createdAt', reportData?.createdAt || new Date())

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-hub-dark">
                Report
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                For the cover use a custom graphics with the same aspect ratio
                as a page in the report. Can be the first page of the report.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={reportHandleSubmit(onSaveReportData)} method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Report title
                    </label>
                    <input
                      id="name"
                      name="name"
                      ref={reportRegister({ required: true })}
                      required
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                  <div className="mt-6">
                    <label
                      htmlFor="authors"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Report authors
                    </label>
                    <input
                      id="authors"
                      name="authors"
                      ref={reportRegister({ required: true })}
                      required
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="description"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <div className="rounded-md shadow-sm">
                      <textarea
                        id="description"
                        rows="3"
                        name="description"
                        ref={reportRegister({ required: true })}
                        required
                        className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      ></textarea>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description of 1-2 sentences.
                    </p>
                  </div>
                  <div className="mt-6">
                    <label className="block text-sm leading-5 font-medium text-gray-700">
                      Report type
                    </label>
                    <fieldset className="mt-4">
                      <legend className="sr-only">Notification method</legend>
                      <div className="space-y-4">
                        {Object.keys(CONST.reportTypes).map((type) => (
                          <div key={type} className="flex items-center">
                            <input
                              id={type}
                              name="reportType"
                              value={type}
                              ref={reportRegister({ required: true })}
                              type="radio"
                              defaultChecked={type === 'debrief'}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label
                              htmlFor={type}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {CONST.reportTypes[type]}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>

                  <div className="mt-6 grid grid-cols-2 gap-6">
                    <div className="flex flex-col col-span-2 md:col-span-1">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        Cover image
                      </label>
                      {coverFile ? (
                        <CoverPreview
                          file={coverFile}
                          onRemoveFile={handleRemoveCoverFile}
                        />
                      ) : (
                        <DroppableFileContainer
                          onFileAccepted={onCoverUploaded}
                          onFileRemove={() => {
                            alert('Remove file')
                          }}
                          accept="image/png"
                          file={coverFile}
                          fileExtensionLabel=".PNG"
                          highlightMissing={triedToMoveForward}
                        />
                      )}
                    </div>
                    <div className="flex flex-col col-span-2 md:col-span-1">
                      <label className="block text-sm leading-5 font-medium text-gray-700">
                        Report file
                      </label>
                      {reportFile ? (
                        <ReportPreview
                          file={reportFile}
                          onRemoveFile={handleRemoveReportFile}
                        />
                      ) : (
                        <DroppableFileContainer
                          onFileAccepted={onReportUploaded}
                          onFileRemove={() => {
                            alert('Remove file')
                          }}
                          accept="application/pdf"
                          file={coverFile}
                          fileExtensionLabel=".PDF"
                          highlightMissing={triedToMoveForward}
                        />
                      )}
                    </div>
                  </div>

                  <div className="mt-6">
                    <div className="">
                      <label
                        htmlFor="fileName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Downloaded file name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="fileName"
                          name="fileName"
                          ref={reportRegister({ required: true })}
                          className="form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder=""
                        />
                        <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          .pdf
                        </span>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        How would you like the downloaded file to be named. Skip
                        the .pdf
                      </p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="radarURL"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Radar url
                    </label>
                    <input
                      type="url"
                      id="radarURL"
                      name="radarURL"
                      ref={reportRegister({ required: false })}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                  <div className="mt-6">
                    <label
                      htmlFor="immersionVideoURL"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Immersion Video URL
                    </label>
                    <input
                      type="url"
                      id="immersionVideoURL"
                      name="immersionVideoURL"
                      ref={reportRegister({ required: false })}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Use only Vimeo links.
                    </p>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="createdAt"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Pulished date (for display only)
                    </label>

                    <DatePicker
                      id="createdAt"
                      ref={reportRegister({
                        name: 'createdAt',
                        required: false,
                      })}
                      selected={watchCreatedAt}
                      onChange={(date) =>
                        reportSetValue('createdAt', date, { shouldDirty: true })
                      }
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Selecting the date will not publish the report
                      automatically.
                    </p>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Next
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

function PreviewAndPublish({
  reportData,
  coverFile,
  reportFile,
  users,
  selectedUsers,
  usersACLS,
  onSavingStarted,
}) {
  const reportCoverURL = useMemo(() => {
    return coverFile ? URL.createObjectURL(coverFile) : null
  }, [coverFile])

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-12 col-gap-6">
        <ul className="mx-auto col-span-1 md:col-span-3 lg:col-span-4 pb-4">
          <MReportPreview
            reportData={reportData}
            coverURL={reportCoverURL}
            preview
          />
        </ul>
        <div className="col-span-1 md:col-span-9 lg:col-span-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 bg-white">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Access type
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        This report access
                      </th>
                      <th className="px-6 py-3 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {users && users.length > 0 && (
                      <>
                        {users.map((user, index) => {
                          let accessTypeLabel = 'Unknown'
                          let reportAccessLabel = 'Granted'
                          const acl = usersACLS[user.id]
                          if (
                            acl.reportsAccess ===
                            CONST.reportAccessTypes.organizationadmin
                          ) {
                            accessTypeLabel = 'Org. admin'
                          } else if (
                            acl.reportsAccess ===
                            CONST.reportAccessTypes.allreports
                          ) {
                            accessTypeLabel = 'All reports (download only)'
                          } else if (
                            acl.reportsAccess ===
                            CONST.reportAccessTypes.shareallreports
                          ) {
                            accessTypeLabel = 'All reports'
                          } else if (
                            acl.reportsAccess ===
                            CONST.reportAccessTypes.shareselectedreports
                          ) {
                            accessTypeLabel = 'Selected reports'
                            if (!selectedUsers.includes(user.id)) {
                              reportAccessLabel = 'Denied'
                            }
                          } else {
                            accessTypeLabel = `Selected reports (download only)`
                            if (!selectedUsers.includes(user.id)) {
                              reportAccessLabel = 'Denied'
                            }
                          }

                          return (
                            <tr key={user.id}>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="text-sm leading-5 font-medium text-hub-dark">
                                  {`${user.firstName} ${user.lastName}`}
                                </div>
                                <div className="text-sm leading-5 text-gray-500">
                                  {user.email}
                                </div>
                              </td>

                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                {accessTypeLabel}
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <span
                                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    reportAccessLabel === 'Granted'
                                      ? 'bg-green-100 text-green-800'
                                      : 'bg-red-100 text-red-800'
                                  }`}
                                >
                                  {reportAccessLabel}
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </>
                    )}
                  </tbody>
                </table>
                <SaveAndShowProgress
                  reportData={reportData}
                  coverFile={coverFile}
                  reportFile={reportFile}
                  users={users}
                  selectedUsers={selectedUsers}
                  onSavingStarted={onSavingStarted}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProgressItem({ label, status = SAVING_STATUS.awaiting }) {
  if (status === SAVING_STATUS.active) {
    return (
      <li>
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
            <span className="absolute h-4 w-4 rounded-full bg-indigo-200"></span>
            <span className="relative block w-2 h-2 bg-indigo-600 rounded-full"></span>
          </div>
          <p className="text-sm leading-5 font-medium text-hub-blue">{label}</p>
        </div>
      </li>
    )
  }
  if (status === SAVING_STATUS.done) {
    return (
      <li>
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
            <svg
              className="h-full w-full text-hub-blue group-hover:text-indigo-800 group-focus:text-indigo-800 transition ease-in-out duration-150"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-hub-dark group-focus:text-hub-dark transition ease-in-out duration-150">
            {label}
          </p>
        </div>
      </li>
    )
  }
  return (
    <li>
      <div href="#" className="group">
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
            <span className="block h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400 group-focus:bg-gray-400 transition ease-in-out duration-150"></span>
          </div>
          <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-hub-dark group-focus:text-hub-dark transition ease-in-out duration-150">
            {label}
          </p>
        </div>
      </div>
    </li>
  )
}

function SaveAndShowProgress({
  reportData,
  coverFile,
  reportFile,
  users,
  selectedUsers,
  onSavingStarted,
  onAllDataSaved,
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [reportDataSavingStatus, setReportDataSavingStatus] = useState(
    SAVING_STATUS.awaiting
  )
  const [coverSavingStatus, setCoverSavingStatus] = useState(
    SAVING_STATUS.awaiting
  )
  const [reportFileSavingStatus, setReportFileSavingStatus] = useState(
    SAVING_STATUS.awaiting
  )
  const [aclsSavingStatus, setAclsSavingStatus] = useState(
    SAVING_STATUS.awaiting
  )

  const startSaving = async (setAsPublished) => {
    const { organizationId } = reportData
    setIsSaving(true)
    onSavingStarted()
    setReportDataSavingStatus(SAVING_STATUS.active)
    const reportRef = await store
      .collection(CONST.frirebaseCollections.reports)
      .add({
        ...reportData,
        isPublished: setAsPublished,
        createtBy: auth.currentUser.uid,
        createdAt: firestoreTimestamp.fromDate(reportData.createdAt),
      })
    setReportDataSavingStatus(SAVING_STATUS.done)
    setCoverSavingStatus(SAVING_STATUS.active)
    setReportFileSavingStatus(SAVING_STATUS.active)
    const reportId = reportRef.id
    const storageRef = files.ref()
    const coverRef = storageRef.child(`${organizationId}/${reportId}/cover.png`)

    const reportFileRef = storageRef.child(
      `${organizationId}/${reportId}/report.pdf`
    )

    coverRef.put(coverFile).then(() => {
      setCoverSavingStatus(SAVING_STATUS.done)
    })
    reportFileRef
      .put(reportFile, {
        contentDisposition: `attachment; filename=${reportData.fileName.trim()}.pdf`,
      })
      .then(() => {
        setReportFileSavingStatus(SAVING_STATUS.done)
      })
    if (selectedUsers && selectedUsers.length > 0) {
      const promises = selectedUsers.map((userId) => {
        const aclId = `${organizationId}--${userId}`

        return store
          .collection(CONST.frirebaseCollections.acl)
          .doc(aclId)
          .update({ [`reportIds.${reportId}`]: true, updated: true })
      })
      Promise.all(promises).then(() => {
        setAclsSavingStatus(SAVING_STATUS.done)
      })
    } else {
      setAclsSavingStatus(SAVING_STATUS.done)
    }
  }

  const allDone = useMemo(() => {
    const done = SAVING_STATUS.done

    return (
      reportFileSavingStatus === done &&
      coverSavingStatus === done &&
      reportDataSavingStatus === done &&
      aclsSavingStatus === done
    )
  }, [
    reportDataSavingStatus,
    coverSavingStatus,
    reportFileSavingStatus,
    aclsSavingStatus,
  ])

  if (!isSaving) {
    return (
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <span className="inline-flex rounded-md shadow-sm">
          <div
            className="cursor-pointer inline-flex justify-center py-2 px-4 border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white transition duration-150 ease-in-out"
            onClick={() => {
              startSaving(false)
            }}
          >
            Save as draft
          </div>
        </span>
        <span className=" inline-flex rounded-md shadow-sm ml-4">
          <div
            className="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            onClick={() => {
              startSaving(true)
            }}
          >
            Publish
          </div>
        </span>
      </div>
    )
  }
  return (
    <div className="py-12 px-4 sm:px-6 grid grid-cols-1 md:grid-cols-2 col-gap-6">
      <nav className="flex justify-start col-span-1 ">
        <ul className="space-y-6">
          <ProgressItem
            label="Report metadata"
            status={reportDataSavingStatus}
          />
          <ProgressItem label="Cover file upload" status={coverSavingStatus} />
          <ProgressItem
            label="Report file upload"
            status={reportFileSavingStatus}
          />
          <ProgressItem label="User access" status={aclsSavingStatus} />
        </ul>
      </nav>
      <div className="flex justify-center items-center col-span-1 ">
        {allDone ? (
          <div className="text-xl font-medium ">
            All done{' '}
            <span role="img" aria-label="confetti">
              🎉
            </span>
            <div>
              <Link
                className="underline text-sm leading-5 font-medium text-hub-blue"
                to={`/organizations/${reportData.organizationId}/reports`}
              >
                Back to reports
              </Link>
            </div>
          </div>
        ) : (
          <Loader type="Rings" color="#5850ec" height={80} width={80} />
        )}
      </div>
    </div>
  )
}

export default function OAddReport({ organizationId }) {
  const [reportData, setReportData] = useState({ createdAt: new Date() })
  const [step, setStep] = useState(1)
  const [coverFile, setCoverFile] = useState()
  const [reportFile, setReportFile] = useState()
  const [willBeNoUsers, setWillBeNoUsers] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [dataSavingStarted, setDataSavingStarted] = useState(false)
  const [allUserIds, setAllUserIds] = useState(null)
  const { data: acls, loading: aclsLoading } = useCollection(
    CONST.frirebaseCollections.acl,
    {
      where: ['organizationId', '==', organizationId],
    }
  )

  const [users, usersLoading] = useFirebaseCollectionDataFromIds(
    CONST.frirebaseCollections.users,
    allUserIds
  )

  useEffect(() => {
    if (!aclsLoading && acls) {
      if (acls.length > 0) {
        const userIds = acls.map((a) => {
          return a.userId
        })
        setAllUserIds(userIds)
      } else {
        setWillBeNoUsers(true)
      }
    }
  }, [acls, aclsLoading, organizationId])

  const usersACLS = useMemo(() => {
    if (!aclsLoading && acls) {
      const tmpUsersACLS = {}
      acls.forEach((a) => {
        tmpUsersACLS[a.userId] = a
      })
      return tmpUsersACLS
    }
    return {}
  }, [acls, aclsLoading])

  const aclUsers = useMemo(() => {
    if (!willBeNoUsers && !usersLoading && users) {
      const tmpAclsToUsers = JSON.parse(JSON.stringify(initialAclsToUsers))
      users.forEach((user) => {
        const accessType = usersACLS[user.id]['reportsAccess']
        tmpAclsToUsers[accessType].push(user)
      })
      return tmpAclsToUsers
    }
    return initialAclsToUsers
  }, [users, usersACLS, usersLoading, willBeNoUsers])

  const handleAddUserToSelected = (userId) => {
    const tmpSelected = [...selectedUsers]
    tmpSelected.push(userId)
    setSelectedUsers(tmpSelected)
  }

  const handleRemoveUserFromSelected = (userId) => {
    const tmpSelected = selectedUsers.filter((id) => id !== userId)
    setSelectedUsers(tmpSelected)
  }

  const canGoToPreview = useMemo(() => {
    return reportData && coverFile && reportFile
  }, [reportData, coverFile, reportFile])

  const handleReportFormSubmit = (data, coverFile, reportFile) => {
    setReportData(data)
    setCoverFile(coverFile)
    setReportFile(reportFile)
    goToStep(2)
  }

  const goToStep = (newStep) => {
    if (newStep) {
      setStep(newStep)
    } else {
      setStep(step + 1)
    }
  }

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <nav className="mt-6 mb-12">
        <ul className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          <StepIndicator
            stepNumber={'1'}
            stepLabel="Report details"
            isActive={step === 1}
            isFinished={step > 1}
            onClick={() => {
              !dataSavingStarted && goToStep(1)
            }}
          />
          <StepIndicator
            stepNumber={'2'}
            stepLabel="User access"
            isActive={step === 2}
            isFinished={step > 2}
            onClick={() => {
              !dataSavingStarted &&
                reportData &&
                coverFile &&
                reportFile &&
                goToStep(2)
            }}
          />
          <StepIndicator
            stepNumber={'3'}
            stepLabel="Review & publish"
            isActive={step === 3}
            isFinished={step > 3}
            isLast
            onClick={() => {
              canGoToPreview && goToStep(3)
            }}
          />
        </ul>
      </nav>

      {step === 1 && (
        <ReportForm
          reportData={reportData}
          coverFile={coverFile}
          reportFile={reportFile}
          onFormSubmitted={handleReportFormSubmit}
        />
      )}
      {step === 2 && (
        <UsersSelection
          aclUsers={aclUsers}
          selectedUsers={selectedUsers}
          onAddToSelected={handleAddUserToSelected}
          onRemoveFromSelected={handleRemoveUserFromSelected}
          onFormSubmitted={() => {
            canGoToPreview && goToStep(3)
          }}
        />
      )}
      {step === 3 && (
        <PreviewAndPublish
          reportData={{ ...reportData, organizationId: organizationId }}
          coverFile={coverFile}
          reportFile={reportFile}
          users={users}
          selectedUsers={selectedUsers}
          usersACLS={usersACLS}
          onSavingStarted={() => {
            setDataSavingStarted(true)
          }}
        />
      )}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Switch, Route, NavLink, Link, Redirect } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import MIntercom from './molecules/MIntercom'

import CONST from './config/Constants'

import { Power } from 'react-feather'
import { FuegoProvider } from '@nandorojo/swr-firestore'

import Logo from './assets/logo.svg'

import { auth, fuego } from './services/Firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import PSignIn from './pages/PSignIn'
import PSignInWithEmailLink from './pages/PSignInWithEmailLink'
import POrganizations from './pages/POrganizations'
import POrganizationDetails from './pages/POrganizationDetails'
import PEndUserReports from './pages/PEndUserReports'

import PReportUserAccess from './pages/PReportUserAccess'
import PReportDetails from './pages/PReportDetails'

import PManageAdminUsers from './pages/PManageAdminUsers'
import PAddAdminUser from './pages/PAddAdminUser'
import PEndUserManageUsers from './pages/PEndUserManageUsers'
import { usePageViews, describeUser } from './services/Analytics'

function AdminOnlyRoute({ claims, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        claims.isRadicleAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function OrgAdminOnlyRoute({ claims, children, ...rest }) {
  const { organizationId } = rest.computedMatch.params
  return (
    <Route
      {...rest}
      render={({ location }) =>
        claims.isRadicleAdmin ||
        claims[organizationId]['a'] ===
          CONST.reportAccessTypes.organizationadmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const [user, userLoading] = useAuthState(auth)
  const [claims, setClaims] = useState()
  const [userEmail, setUserEmail] = useState()

  usePageViews()
  useEffect(() => {
    if (user) {
      user.getIdTokenResult(true).then((tokenResult) => {
        setClaims(tokenResult.claims.radAuth)
      })
      setUserEmail(user.email)
      describeUser(user.uid, { email: user.email })
    }
  }, [user])

  const handleLogout = () => {
    auth.signOut()
  }

  if (userLoading) {
    return null
    // return  <OLoader />
  }

  if (!user && !userLoading) {
    return (
      <Switch>
        <Route exact path="/sign-in-with-email-link/">
          <PSignInWithEmailLink />
        </Route>
        <Route exact path="/sign-in/">
          <PSignIn />
        </Route>
        <Redirect from="/" to="/sign-in/" />
      </Switch>
    )
  }

  if (user && !claims) {
    return null
  }

  // console.log('Claims', claims)

  return (
    <>
      <MIntercom />
      <FuegoProvider fuego={fuego}>
        {/* // <!-- Background color split screen for large screens --> */}
        {/* <div className="fixed top-0 left-0 w-1/2 h-full bg-white"></div>
      <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50"></div> */}
        <div className="relative min-h-screen flex flex-col bg-gray-50">
          {/* <!-- Navbar --> */}
          <nav className="flex-shrink-0 bg-hub-dark">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                {/* <!-- Logo section --> */}
                <div className="flex items-center px-2 lg:px-0 xl:w-64">
                  <div className="flex-shrink-0">
                    <Link to="/">
                      <ReactSVG src={Logo} className="h-8 w-auto" />
                    </Link>
                  </div>
                </div>

                {/* <!-- Search section --> */}
                <div className="flex-1 flex justify-center lg:justify-end"></div>
                {/* <!-- Mobile menu button --> */}
                {/* <div className="flex lg:hidden">
                  
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-indigo-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 focus:text-white transition duration-150 ease-in-out"
                    aria-label="Main menu"
                    aria-expanded="false"
                  >
                    
                    <svg
                      className="block h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h8m-8 6h16"
                      />
                    </svg>
                    
                    <svg
                      className="hidden h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div> */}
                {/* <!-- Links section --> */}
                <div className="hidden lg:block lg:w-96">
                  <div className="flex items-center justify-end">
                    {claims.isRadicleAdmin && (
                      <div className="flex">
                        <NavLink
                          to="/organizations"
                          className="px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-50 hover:text-white focus:outline-none focus:text-white focus:bg-hub-blue transition duration-150 ease-in-out "
                          activeClassName="text-white outline-none bg-hub-blue"
                        >
                          Organizations & Reports
                        </NavLink>

                        <NavLink
                          to="/admins"
                          className="px-3 py-2 rounded-md text-sm leading-5 font-medium text-gray-50 hover:text-white focus:outline-none focus:text-white focus:bg-hub-blue transition duration-150 ease-in-out "
                          activeClassName="text-white outline-none bg-hub-blue"
                        >
                          Admin Users
                        </NavLink>
                      </div>
                    )}
                    {/* <!-- Profile dropdown --> */}
                  </div>
                </div>
                <div className="hidden lg:block ">
                  <div className="ml-4 relative flex-shrink-0 flex items-center justify-center">
                    <span className="text-xs text-gray-50 mr-2">
                      {userEmail}
                    </span>
                    <div>
                      <div
                        className="cursor-pointer flex text-sm rounded-full text-white focus:outline-none focus:shadow-solid transition duration-150 ease-in-out"
                        id="user-menu"
                        aria-label="User menu"
                        aria-haspopup="true"
                        onClick={handleLogout}
                      >
                        <Power className="h-4 w-4 text-gray-50" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--
      Mobile menu, toggle classes based on menu state.

      Menu open: "block", Menu closed: "hidden"
    --> */}
            {/* <div className="hidden lg:hidden">
            <div className="px-2 pt-2 pb-3">
              <a
                href="#"
                className="block px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-800 focus:outline-none focus:text-indigo-100 focus:bg-indigo-600 transition duration-150 ease-in-out"
              >
                Dashboard
              </a>
              <a
                href="#"
                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600 transition duration-150 ease-in-out"
              >
                Support
              </a>
            </div>
            <div className="pt-4 pb-3 border-t border-indigo-800">
              <div className="px-2">
                <a
                  href="#"
                  className="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600 transition duration-150 ease-in-out"
                >
                  Your Profile
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600 transition duration-150 ease-in-out"
                >
                  Settings
                </a>
                <a
                  href="#"
                  className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600 focus:outline-none focus:text-white focus:bg-indigo-600 transition duration-150 ease-in-out"
                >
                  Sign out
                </a>
              </div>
            </div>
          </div> */}
          </nav>

          {/* <!-- wrapper --> */}
          <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 flex">
            <Switch>
              <Redirect from="/sign-in-with-email-link" to="/" />
              <Route path="/" exact>
                <PEndUserReports key={user.uid} claims={claims} />
              </Route>
              <AdminOnlyRoute claims={claims} path="/organizations" exact>
                <POrganizations />
              </AdminOnlyRoute>
              <AdminOnlyRoute
                claims={claims}
                path="/organizations/:organizationId"
              >
                <POrganizationDetails claims={claims} />
              </AdminOnlyRoute>
              <Route exact path="/reports/:reportId/users">
                <PReportUserAccess claims={claims} />
              </Route>
              <Route exact path="/reports/:reportId">
                <PReportDetails claims={claims} />
              </Route>
              <AdminOnlyRoute exact claims={claims} path="/admins">
                <PManageAdminUsers />
              </AdminOnlyRoute>
              <AdminOnlyRoute exact claims={claims} path="/admins/add">
                <PAddAdminUser />
              </AdminOnlyRoute>
              <OrgAdminOnlyRoute
                claims={claims}
                path="/users-for/:organizationId"
              >
                <PEndUserManageUsers claims={claims} />
              </OrgAdminOnlyRoute>
            </Switch>
          </div>
        </div>
      </FuegoProvider>
    </>
  )
}

export default App

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Edit, Download } from 'react-feather'
import { functions } from '../services/Firebase'
import Loader from 'react-loader-spinner'

import { EVENTS, logEvent } from '../services/Analytics'

//TODO: Handle loading errors
const getReportDownloadURL = functions.httpsCallable('getReportDownloadURL')
export default function MReportPreview({
  reportData,
  coverURL,
  reportURL,
  preview = false,
  hideEdit,
  hideAllActions = false,
}) {
  const [lodingDownloadLink, setLodingDownloadLink] = useState(false)
  const startDownload = async () => {
    let actualReportUrl
    if (!preview) {
      setLodingDownloadLink(true)
      const url = await getReportDownloadURL({ reportId: reportData.id })
      logEvent(EVENTS.REPORT.DOWNLOADED, {
        reportName: reportData.name,
        reportId: reportData.id,
      })

      setLodingDownloadLink(false)
      actualReportUrl = url.data.downloadURL
    } else {
      actualReportUrl = reportURL
    }
    window.location.assign(actualReportUrl)
  }
  return (
    <>
      <li
        key={reportData.id}
        className="col-span-1 flex flex-col bg-white rounded-lg shadow"
      >
        <div className="flex-1 flex flex-col p-8">
          <div className="mt-2 flex flex-col justify-center items-center content-center  rounded-md bg-gray-50">
            <div className="flex flex-col flex-grow justify-center items-center content-center bg-gray-50 w-full relative">
              <div className="aspect-ratio-16/9 h-0 overflow-hidden" />

              <img
                src={coverURL}
                alt="Cover"
                className={`absolute z-10 left-0 top-0 w-full h-full object-cover rounded-md ${
                  !coverURL && 'shadow-xs'
                }`}
              ></img>

              {!coverURL && (
                <div className="absolute z-20 top-0 left-0 h-full w-full flex flex-grow items-center justify-center border-2 border-dashed bg-gray-50 rounded-md ">
                  <p className="text-sm text-gray-400">No cover</p>
                </div>
              )}
            </div>
          </div>
          <h3 className="pl-1 mt-6 text-hub-dark text-sm leading-5 font-medium">
            {reportData.name}
          </h3>
          <dl className="pl-1 mt-1 flex-grow flex flex-col justify-between">
            <dt className="sr-only">Title</dt>
            <dd className="text-gray-500 text-sm leading-5">
              {reportData.authors}
            </dd>
            <dt className="sr-only">Role</dt>
            <dd className="mt-3">
              {preview ? (
                <span className="px-2 py-1 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full">
                  Preview
                </span>
              ) : (
                <>
                  {reportData.isPublished ? (
                    <span className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                      Published
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full">
                      Draft
                    </span>
                  )}
                </>
              )}
            </dd>
          </dl>
        </div>
        {!preview && !hideAllActions && (
          <div className="border-t border-gray-200">
            <div className="-mt-px flex">
              {!hideEdit && (
                <div className="w-0 flex-1 flex border-r border-gray-200">
                  {/* <div className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"> */}
                  <Link
                    to={`/organizations/${reportData.organizationId}/reports/${reportData.id}/edit`}
                    className=" cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                  >
                    <Edit className="w-4 text-gray-700" />
                    <span className="ml-3">Edit</span>
                  </Link>
                  {/* </div> */}
                </div>
              )}
              <div className="-ml-px w-0 flex-1 flex">
                <div
                  onClick={() => {
                    !lodingDownloadLink && startDownload()
                  }}
                  className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                >
                  {lodingDownloadLink ? (
                    <>
                      <Loader
                        type="Rings"
                        color="#5850ec"
                        height={24}
                        width={24}
                      />
                      <span className="ml-3">Loading...</span>
                    </>
                  ) : (
                    <>
                      <Download className="w-4 text-gray-700" />
                      <span className="ml-3">Download</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </li>
    </>
  )
}

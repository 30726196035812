import React, { useState } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { Book, Award, Users, X } from 'react-feather'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { store } from '../services/Firebase'
import OLoader from '../organisms/OLoader'

import CONST from '../config/Constants'

//TODO: Handle loading errors

Modal.setAppElement('#root')
export default function POrganizations() {
  const [addOrganizationMode, setAddOrganizationMode] = useState(false)
  const [isOrganizationSaving, setIsOrganizationSaving] = useState(false)
  const { register, handleSubmit } = useForm()

  const {
    data: organizations,
    loading: organizationsLoading,
  } = useCollection(CONST.frirebaseCollections.organizations, { listen: true })

  const onAddOrganization = async (data) => {
    try {
      setIsOrganizationSaving(true)
      await store.collection(CONST.frirebaseCollections.organizations).add({
        name: data.organizationName,
        adminsCount: 0,
        usersCount: 0,
        reportsCount: 0,
      })
      setIsOrganizationSaving(false)
      setAddOrganizationMode(false)
    } catch (err) {
      setIsOrganizationSaving(false)
    }
  }
  const closeModal = () => {
    setAddOrganizationMode(false)
  }
  if (organizationsLoading) {
    return <OLoader />
  }
  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className=" px-4 py-5 sm:px-6">
          <div className="">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3 className="text-lg md:text-3xl leading-6 font-bold text-hub-dark">
                  Organizations
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-hub-blue hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                    onClick={() => {
                      setAddOrganizationMode(true)
                    }}
                  >
                    Add Organization
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul>
              {organizations.map((org, index) => (
                <li
                  key={org.id}
                  className={`cursor-pointer ${
                    index > 0 && 'border-t border-gray-200'
                  }`}
                >
                  <Link to={`/organizations/${org.id}`}>
                    <div className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div>
                              <div className="text-sm leading-5 font-medium text-hub-blue truncate">
                                {org.name}
                              </div>
                              <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                <Book className="w-4 mr-2" />
                                <span className="truncate">
                                  {`${org.reportsCount} ${
                                    org.reportsCount === 1
                                      ? 'report'
                                      : 'reports'
                                  }`}
                                </span>
                              </div>
                            </div>
                            {/* <div className="hidden md:block">
                              <div>
                                <div className="flex items-center text-sm leading-5 text-hub-dark">
                                  <Award className="w-4 mr-2" />
                                  <span className="truncate">
                                    {`${org.adminsCount} ${
                                      org.adminsCount === 1 ? 'admin' : 'admins'
                                    }`}
                                  </span>
                                </div>
                                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                  <Users className="w-4 mr-2" />
                                  <span className="truncate">
                                    {`${org.usersCount} ${
                                      org.usersCount === 1 ? 'user' : 'users'
                                    }`}
                                  </span>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div>
                          <svg
                            className="h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Modal
        isOpen={addOrganizationMode}
        onRequestClose={closeModal}
        contentLabel="Add Organization"
        style={{
          content: {
            position: 'absolute',
            float: 'left',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="flex flex-col">
          <X className="w-8 self-end cursor-pointer" onClick={closeModal} />
          <div className="flex flex-col justify-start py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="py-8 px-4 sm:px-10">
                <form onSubmit={handleSubmit(onAddOrganization)} method="POST">
                  <h2 className="mb-6 leading-relaxed text-3xl font-bold text-hub-dark">
                    New Organization
                  </h2>
                  <div>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="organizationName"
                        name="organizationName"
                        disabled={isOrganizationSaving}
                        required
                        placeholder="Organization name"
                        ref={register({ required: true })}
                        className="bg-gray-50 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <div className="flex w-full justify-end">
                      {isOrganizationSaving ? (
                        <div className="cursor-wait w-44 flex justify-center py-2 px-4 border border-transparent text-sm rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Saving...
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="w-44 flex justify-center py-2 px-4 border border-transparent text-sm rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Create
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCollection } from '@nandorojo/swr-firestore'
import Loader from 'react-loader-spinner'
import AButton from '../atoms/AButton'

import { store, functions, auth } from '../services/Firebase'
import CONST from '../config/Constants'

import { EVENTS, logEvent } from '../services/Analytics'

const ACCESS_LOWER = -1
const ACCESS_SAME = 0
const ACCESS_HIGHER = 1
const REPORT_ACCESS_WEIGHTS = {
  [CONST.reportAccessTypes.selectedreports]: 0,
  [CONST.reportAccessTypes.shareselectedreports]: 1,
  [CONST.reportAccessTypes.allreports]: 2,
  [CONST.reportAccessTypes.shareallreports]: 3,
  [CONST.reportAccessTypes.organizationadmin]: 4,
}

const remoteAddUser = functions.httpsCallable('addUser')

function ReportsSelection({
  reports,
  selectedReports = [],
  onAddToSelected,
  onRemoveFromSelected,
  disabled = false,
}) {
  return (
    <div className="flex flex-col px-4 sm:px-6 my-4 sm:my-5">
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <fieldset className="" disabled={disabled}>
              <legend className="text-base leading-6 font-medium text-hub-dark">
                Available <span className=" text-indigo-700 ">reports</span>
              </legend>
              {reports &&
                reports.map((report) => (
                  <div key={report.id} className="mt-4">
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id={report.id}
                          type="checkbox"
                          checked={selectedReports.includes(report.id)}
                          className="form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                          onChange={(e) => {
                            e.target.checked
                              ? onAddToSelected(report.id)
                              : onRemoveFromSelected(report.id)
                          }}
                        />
                      </div>
                      <div className="flex-grow ml-3 text-sm leading-5 grid grid-cols-1 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 sm:col-gap-2 md:col-gap-3 lg:col-gap-4">
                        <div className="col-span-1 sm:col-span-3 md:col-span-4 lg:col-span-5">
                          <label
                            htmlFor={report.id}
                            className="font-medium text-gray-700 break-word"
                          >
                            {report.name}
                          </label>
                          <p className="text-gray-500">{report.authors}</p>
                        </div>
                        <div className="col-span-1 flex items-center justify-start md:justify-center mt-2 md:mt-0">
                          <>
                            {!report.isPublished && (
                              <span className="px-2 py-1 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full">
                                Draft
                              </span>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function OAddUser({
  organizationId,
  reportId,
  onlyPublishedReports,
  claims,
}) {
  const [accessType, setAccessType] = useState(
    CONST.reportAccessTypes.shareallreports
  )
  const [selectedReports, setSelectedReports] = useState([])
  const [userData, setUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const whereQuery = useMemo(() => {
    let tmpWhereQuery = {
      where: ['organizationId', '==', organizationId],
      listen: true,
    }

    if (onlyPublishedReports) {
      tmpWhereQuery = {
        where: [
          ['organizationId', '==', organizationId],
          ['isPublished', '==', true],
        ],
        listen: true,
      }
    }

    return tmpWhereQuery
  }, [organizationId, onlyPublishedReports])

  const { data: reports } = useCollection(
    organizationId ? CONST.frirebaseCollections.reports : null,
    whereQuery
  )
  const handleUserFormSubmit = (data) => {
    setUserData(data)
    setShowConfirmation(true)
  }

  useEffect(() => {
    if (reportId) {
      // setAccessType(CONST.reportAccessTypes.shareselectedreports)
      setSelectedReports([reportId])
    }
  }, [reportId])

  const canAddOrgAdmins = useMemo(() => {
    let tmpCanAddOrAdmins = false
    if (claims && organizationId) {
      if (claims.isRadicleAdmin) {
        tmpCanAddOrAdmins = true
      } else if (
        claims[organizationId]['a'] ===
        CONST.reportAccessTypes.organizationadmin
      ) {
        tmpCanAddOrAdmins = true
      }
    }
    return tmpCanAddOrAdmins
  }, [claims, organizationId])

  // console.log('canAddOrgAdmins', canAddOrgAdmins)

  /**
   *
   * @param {*} firstAccess
   * @param {*} secondAccess
   * @returns -1 if first is lower then second, 0 if the same 1 if higher
   */

  const isReportAccessHigher = useCallback(
    (firstAccessType, secondAccessType) => {
      let points =
        REPORT_ACCESS_WEIGHTS[firstAccessType] -
        REPORT_ACCESS_WEIGHTS[secondAccessType]
      if (points === 0) {
        return ACCESS_SAME
      } else if (points < 0) {
        return ACCESS_LOWER
      } else {
        return ACCESS_HIGHER
      }
    },
    []
  )

  const saveUserData = useCallback(async () => {
    setIsSaving(true)
    const result = await remoteAddUser(userData)
    const { userId } = result.data
    const aclId = `${organizationId}--${userId}`
    const existingACLObject = await store
      .collection(CONST.frirebaseCollections.acl)
      .doc(aclId)
      .get()
    let aclObject
    let shouldUpdateRemote = true
    let shouldMergeRemote = false
    if (existingACLObject.exists) {
      // console.log("++ ACL existis!", existingACLObject.exists, " ACL value", existingACLObject.data())
      const aclData = existingACLObject.data()
      const currentReportsAccess = aclData['reportsAccess']
      aclObject = {}
      const reportsAccessWeight = isReportAccessHigher(
        currentReportsAccess,
        userData.reportsAccess
      )

      if (
        reportsAccessWeight === ACCESS_SAME &&
        (currentReportsAccess === CONST.reportAccessTypes.selectedreports ||
          currentReportsAccess === CONST.reportAccessTypes.shareselectedreports)
      ) {
        // console.log("--- SAME && selected", {remote: currentReportsAccess, local: userData.reportsAccess} )
        const currentReportIds = aclData['reportIds']
        const reportIdsObject = {}
        selectedReports.forEach((id) => {
          reportIdsObject[`${id}`] = true
        })
        aclObject['reportIds'] = { ...currentReportIds, ...reportIdsObject }
        shouldMergeRemote = true
      } else if (
        reportsAccessWeight === ACCESS_SAME ||
        reportsAccessWeight === ACCESS_HIGHER
      ) {
        // console.log("+++ SAME or REMOTE is higher", {remote: currentReportsAccess, local: userData.reportsAccess} )
        shouldUpdateRemote = false
      } else {
        // console.log("+++ REMOTE is lower", {remote: currentReportsAccess, local: userData.reportsAccess} )
        aclObject = {
          organizationId,
          userId: userId,
          reportsAccess: userData.reportsAccess,
          invitedBy: auth.currentUser.uid,
        }
      }
    } else {
      // there's no ACL for that user
      aclObject = {
        organizationId,
        userId: userId,
        reportsAccess: userData.reportsAccess,
        invitedBy: auth.currentUser.uid,
      }
      if (
        userData.reportsAccess === CONST.reportAccessTypes.selectedreports ||
        userData.reportsAccess === CONST.reportAccessTypes.shareselectedreports
      ) {
        const reportIdsObject = {}
        selectedReports.forEach((id) => {
          reportIdsObject[`${id}`] = true
        })
        aclObject['reportIds'] = reportIdsObject
      }
    }

    if (shouldUpdateRemote) {
      await store
        .collection(CONST.frirebaseCollections.acl)
        .doc(aclId)
        .set(aclObject, { merge: shouldMergeRemote })
    }
    logEvent(EVENTS.REPORT.SHARED, {
      organizationId: organizationId,
      invitedUserID: userId,
      reportsAccess: userData.reportsAccess,
      invitedBy: auth.currentUser.uid,
    })

    setIsSaving(false)
    setAllDone(true)
  }, [userData, organizationId, isReportAccessHigher, selectedReports])

  const { register, handleSubmit, reset } = useForm({
    defaultValues: userData,
  })

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        reset()
        setShowConfirmation(false)
        setAllDone(false)
      }, 4000)
    }
  }, [allDone, reset])

  const handleAddReportToSelected = (reportId) => {
    const tmpSelected = [...selectedReports]
    tmpSelected.push(reportId)
    setSelectedReports(tmpSelected)
  }

  const handleRemoveReportsFromSelected = (reportId) => {
    const tmpSelected = selectedReports.filter((id) => id !== reportId)
    setSelectedReports(tmpSelected)
  }

  const showReports = useMemo(() => {
    return (
      accessType === CONST.reportAccessTypes.selectedreports ||
      accessType === CONST.reportAccessTypes.shareselectedreports
    )
  }, [accessType])

  return (
    <div className="flex flex-col px-4 pt-5 sm:px-6">
      <form onSubmit={handleSubmit(handleUserFormSubmit)} method="POST">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-hub-dark">
                  Invite user
                </h3>
                <p className="mt-1 text-sm leading-5 text-gray-600">
                  Users sign-in using special sin-in links sent to their e-mail.
                  Please double check for any typos in the e-mail.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        First name
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        disabled={isSaving || allDone}
                        ref={register({ required: true })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        id="lastName"
                        name="lastName"
                        disabled={isSaving || allDone}
                        ref={register({ required: true })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        id="email"
                        name="email"
                        disabled={isSaving || allDone}
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                          },
                        })}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          <div className="py-5">
            <div className=""></div>
          </div>
        </div>
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-hub-dark">
                  Reports access for invited user
                </h3>
                {/* <p className="mt-1 text-sm leading-5 text-gray-600">
                  Organization admins will be able to invite new users to
                  organization.
                </p> */}
              </div>
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <fieldset disabled={isSaving || allDone}>
                    <legend className="text-base leading-6 font-medium text-hub-dark">
                      Access type
                    </legend>

                    <div className="mt-4">
                      {/* <div className="flex items-center">
                        <input
                          id={CONST.reportAccessTypes.selectedreports}
                          value={CONST.reportAccessTypes.selectedreports}
                          checked={
                            accessType ===
                            CONST.reportAccessTypes.selectedreports
                          }
                          name="reportsAccess"
                          type="radio"
                          ref={register({ required: true })}
                          onChange={(e) => {
                            setAccessType(e.target.value)
                          }}
                          className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                        />
                        <label htmlFor="selectedreports" className="ml-3">
                          <span className="block text-sm leading-5 font-medium text-gray-700">
                            Download selected reports
                          </span>
                        </label>
                      </div> */}
                      <div className="flex items-center">
                        <input
                          id={CONST.reportAccessTypes.shareselectedreports}
                          value={CONST.reportAccessTypes.shareselectedreports}
                          checked={
                            accessType ===
                            CONST.reportAccessTypes.shareselectedreports
                          }
                          name="reportsAccess"
                          type="radio"
                          ref={register({ required: true })}
                          onChange={(e) => {
                            setAccessType(e.target.value)
                          }}
                          className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                        />
                        <label htmlFor="selectedreports" className="ml-3">
                          <span className="block text-sm leading-5 font-medium text-gray-700">
                            Download and share selected reports
                          </span>
                          {/* <p className="text-gray-500 text-xs">
                            Will be able invite other users to selected reports
                          </p> */}
                        </label>
                      </div>

                      {/* <div className="mt-4 flex items-center">
                        <input
                          id={CONST.reportAccessTypes.allreports}
                          value={CONST.reportAccessTypes.allreports}
                          checked={
                            accessType === CONST.reportAccessTypes.allreports
                          }
                          name="reportsAccess"
                          type="radio"
                          ref={register({ required: true })}
                          onChange={(e) => {
                            setAccessType(e.target.value)
                          }}
                          className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                        />
                        <label htmlFor="allreports" className="ml-3">
                          <span className="block text-sm leading-5 font-medium text-gray-700">
                            Download all current and future reports
                          </span>
                          <p className="text-gray-500 text-xs">
                            Will NOT be able invite other users
                          </p>
                        </label>
                      </div> */}

                      <div className="mt-4 flex items-center">
                        <input
                          id={CONST.reportAccessTypes.shareallreports}
                          value={CONST.reportAccessTypes.shareallreports}
                          checked={
                            accessType ===
                            CONST.reportAccessTypes.shareallreports
                          }
                          name="reportsAccess"
                          type="radio"
                          ref={register({ required: true })}
                          onChange={(e) => {
                            setAccessType(e.target.value)
                          }}
                          className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                        />
                        <label htmlFor="allreports" className="ml-3">
                          <span className="block text-sm leading-5 font-medium text-gray-700">
                            Download and share all current and future reports
                          </span>
                          <p className="text-gray-500 text-xs">
                            Will be able invite other users to access all
                            available reports
                          </p>
                        </label>
                      </div>

                      {canAddOrgAdmins && (
                        <div className="mt-4  flex items-center">
                          <input
                            id={CONST.reportAccessTypes.organizationadmin}
                            value={CONST.reportAccessTypes.organizationadmin}
                            name="reportsAccess"
                            type="radio"
                            ref={register({ required: true })}
                            onChange={(e) => {
                              setAccessType(e.target.value)
                            }}
                            className="form-radio h-4 w-4 text-hub-blue transition duration-150 ease-in-out"
                          />
                          <label htmlFor="organizationadmin" className="ml-3">
                            <span className="block text-sm leading-5 font-medium text-gray-700">
                              Organization admin
                            </span>
                            <p className="text-gray-500 text-xs">
                              Access all reports, invite other admins and manage
                              user access across all reports
                            </p>
                          </label>
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
                {showReports && (
                  <ReportsSelection
                    reports={reports}
                    selectedReports={selectedReports}
                    onAddToSelected={handleAddReportToSelected}
                    onRemoveFromSelected={handleRemoveReportsFromSelected}
                    disabled={isSaving || allDone}
                  />
                )}

                {isSaving && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <Loader
                      type="Rings"
                      color="#5850ec"
                      height={40}
                      width={40}
                    />
                  </div>
                )}
                {allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                    <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                      <p className="text-sm leading-5 text-gray-600 mr-4">
                        All done{' '}
                        <span role="img" aria-label="confetti">
                          🎉
                        </span>
                      </p>{' '}
                      {/* <Link
                        className="underline text-sm leading-5  text-hub-blue"
                        to={`/organizations/${organizationId}/users`}
                      >
                        Back to users
                      </Link> */}
                    </div>
                  </div>
                )}

                {!isSaving && !allDone && (
                  <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                    {showConfirmation ? (
                      <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                        <p className="text-sm leading-5 text-gray-600 mr-4">
                          Are you sure?
                        </p>
                        <span className="inline-flex rounded-md shadow-sm">
                          <AButton
                            secondary
                            onClick={() => {
                              setShowConfirmation(false)
                            }}
                          >
                            Cancel
                          </AButton>
                        </span>
                        <span className=" inline-flex rounded-md shadow-sm ml-4">
                          <AButton onClick={saveUserData}>
                            Yes, save and invite
                          </AButton>
                        </span>
                      </div>
                    ) : (
                      <AButton type="submit">Save and send invitation</AButton>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="hidden sm:block">
        <div className="py-5">
          <div className=""></div>
        </div>
      </div>
    </div>
  )
}

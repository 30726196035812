import React from 'react'
import { DownloadCloud } from 'react-feather'

export default function MUserWithCheckboxListItem({
  user,
  downloadsCount,
  onChange = () => {},
  disabled,
  checked,
  isDirty,
}) {
  return (
    <div className="mt-4">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="comments"
            type="checkbox"
            checked={checked}
            disabled={disabled}
            className={`${
              disabled && 'opacity-50'
            } form-checkbox h-4 w-4 text-hub-blue transition duration-150 ease-in-out mr-1`}
            onChange={onChange}
          />
        </div>
        <div className="grid grid-cols-12 col-gap-2 flex-grow">
          <div className="ml-3 text-sm leading-5 col-span-9">
            <label
              htmlFor="comments"
              className={`${
                isDirty ? 'text-orange-700' : 'text-gray-700'
              } font-medium`}
            >
              {user.firstName} {user.lastName}
            </label>
            <p className="text-gray-500">{user.email}</p>
          </div>
          <div className="text-sm leading-5 col-span-3 flex flex-row items-center justify-end">
            <div className="text-gray-500 w-16 h-8 bg-gray-100 rounded-md px-2 flex items-center justify-center">
              <div className="text-xs text-hub-dark-gray font-medium flex items-center justify-end">
                <span>{downloadsCount}</span>
                <DownloadCloud className="ml-1 w-4 h-4 text-hub-dark-gray" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

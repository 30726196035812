import React, { useState, useCallback, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'

import { store, auth } from '../services/Firebase'
import CONST from '../config/Constants'

export default function PAddAdminUser() {
  const [userData, setUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const handleUserFormSubmit = (data) => {
    setUserData(data)
    setShowConfirmation(true)
  }

  const saveUserData = useCallback(async () => {
    setIsSaving(true)
    const usersWithThisEmailSnapshot = await store
      .collection(CONST.frirebaseCollections.users)
      .where('email', '==', userData.email)
      .get()
    if (usersWithThisEmailSnapshot.docs.length === 0) {
      // console.log('Admin user with this email not found')
      await store.collection(CONST.frirebaseCollections.users).add({
        ...userData,
        isRadicleAdmin: true,
        didJoin: false,
        createdBy: auth.currentUser.uid,
      })
    } else {
      console.log('Admin user with this email found')
    }

    setIsSaving(false)
    setAllDone(true)
  }, [userData])

  const { register, handleSubmit, reset } = useForm({
    defaultValues: userData,
  })

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        reset()
        setShowConfirmation(false)
        setAllDone(false)
        setRedirect(true)
      }, 4000)
    }
  }, [allDone, reset])

  if (redirect) {
    return <Redirect to="/admins" />
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-1">
            <div className="-ml-4 -mt-2 flex-grow flex items-center flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2 flex flex-grow">
                <h3 className="text-lg md:text-2xl leading-6 font-medium text-hub-blue">
                  <Link to="/admins">Admin Users</Link>{' '}
                  <span className="text-xl mr-1 ml-1">{'>'}</span>
                  <span className="text-hub-dark">Add new admin</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4 pt-5 sm:px-6">
          <form onSubmit={handleSubmit(handleUserFormSubmit)} method="POST">
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-hub-dark">
                      Admin user
                    </h3>
                    <p className="mt-1 text-sm leading-5 text-gray-600">
                      Keep in mind that Radicle admins can do everything that's
                      possible across the system for all organizations. Those
                      are so called super-admins. Consider adding and
                      organization admin if that's not what you're looking for.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            disabled={isSaving || allDone}
                            ref={register({ required: true })}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            disabled={isSaving || allDone}
                            ref={register({ required: true })}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Email address
                          </label>
                          <input
                            id="email"
                            name="email"
                            disabled={isSaving || allDone}
                            ref={register({
                              required: 'Required',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'invalid email address',
                              },
                            })}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                    </div>
                    {isSaving && (
                      <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                        <Loader
                          type="Rings"
                          color="#5850ec"
                          height={40}
                          width={40}
                        />
                      </div>
                    )}
                    {allDone && (
                      <div className="px-4 py-2 bg-gray-50 text-right sm:px-6 flex flex-row items-center justify-center">
                        <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                          <p className="text-sm leading-5 text-gray-600 mr-4">
                            All done{' '}
                            <span role="img" aria-label="confetti">
                              🎉
                            </span>
                          </p>{' '}
                          {/* <Link
                        className="underline text-sm leading-5  text-hub-blue"
                        to={`/organizations/${organizationId}/users`}
                      >
                        Back to users
                      </Link> */}
                        </div>
                      </div>
                    )}

                    {!isSaving && !allDone && (
                      <div className="px-4 py-2 bg-gray-50 text-right sm:px-6">
                        {showConfirmation ? (
                          <div className="bg-gray-50 text-right flex flex-row justify-end items-center">
                            <p className="text-sm leading-5 text-gray-600 mr-4">
                              Are you sure?
                            </p>
                            <span className="inline-flex rounded-md shadow-sm">
                              <div
                                className="cursor-pointer inline-flex justify-center py-2 px-4 border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white transition duration-150 ease-in-out"
                                onClick={() => {
                                  setShowConfirmation(false)
                                }}
                              >
                                Cancel
                              </div>
                            </span>
                            <span className=" inline-flex rounded-md shadow-sm ml-4">
                              <div
                                className="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                                onClick={saveUserData}
                              >
                                Yes, save and invite
                              </div>
                            </span>
                          </div>
                        ) : (
                          <button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out">
                            Save and send invitation
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="hidden sm:block">
            <div className="py-5">
              <div className=""></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

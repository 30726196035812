import React, { useEffect, useRef, useState } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { Link, useRouteMatch } from 'react-router-dom'

import { files } from '../services/Firebase'
import MReportPreview from '../molecules/MReportPreview'
import OLoader from '../organisms/OLoader'

import CONST from '../config/Constants'

//TODO: Handle loading errors

export default function OOrganizationReports({ organizationId }) {
  const { current: coverDownloadURLsRef } = useRef({})
  const [coverDownloadURLs, setCoverDownloadURLs] = useState({})
  const { data: reports, loading: reportsLoading } = useCollection(
    CONST.frirebaseCollections.reports,
    {
      where: ['organizationId', '==', organizationId],

      orderBy: ['createdAt', 'desc'],
      listen: true,
    }
  )
  const { url } = useRouteMatch()

  useEffect(() => {
    async function resolvePromises(promises) {
      await Promise.all(promises)
      setCoverDownloadURLs({ ...coverDownloadURLsRef })
      return
    }
    if (!reportsLoading && reports && reports.length > 0) {
      const promises = []
      reports.forEach((report) => {
        if (!coverDownloadURLsRef[report.id]) {
          const coverPath = `${organizationId}/${report.id}/cover.png`
          const promise = files
            .ref(coverPath)
            .getDownloadURL()
            .then((url) => {
              coverDownloadURLsRef[report.id] = url
            })
            .catch((err) => {
              console.log('Download url error', err)
            })
          promises.push(promise)
        }
        resolvePromises(promises)
      })
    }
  }, [coverDownloadURLsRef, organizationId, reports, reportsLoading])

  if (reportsLoading) {
    return (
      <div className="px-4 py-5 sm:px-6 flex flex-col flex-grow flex-1">
        <OLoader />
      </div>
    )
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <li
            key="add-report"
            className={`${
              reports.length === 0 && 'h-96'
            } col-span-1 flex flex-col text-center bg-gray-100 rounded-lg border-2 border-dashed border-gray-200 items-center justify-center pa-12`}
          >
            <Link
              to={`${url}/add`}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
            >
              Add Report
            </Link>
          </li>
          {reports.map((report, index) => (
            <MReportPreview
              coverURL={coverDownloadURLs[report.id]}
              key={report.id}
              reportData={report}
            />
          ))}
        </ul>
      </div>
    </>
  )
}

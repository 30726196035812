import React, { useCallback, useState, useEffect } from 'react'
import { X, AlertTriangle } from 'react-feather'
import { useCollection } from '@nandorojo/swr-firestore'
import { Link, useRouteMatch } from 'react-router-dom'
import Modal from 'react-modal'
import { auth, store } from '../services/Firebase'

import OLoader from '../organisms/OLoader'

import CONST from '../config/Constants'

//TODO: Handle loading errors
Modal.setAppElement('#root')
export default function PManageAdminUsers({ organizationId }) {
  const { url } = useRouteMatch()
  const [lastPasswordResetEmail, setLastPasswordResetEmail] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null)
  const [userEmailToBeDeleted, setUserEmailToBeDeleted] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  const { data: users, loding: usersLoading } = useCollection(
    CONST.frirebaseCollections.users,
    {
      where: ['isRadicleAdmin', '==', true],

      listen: true,
    }
  )

  const sendResetPasswordLink = useCallback((emailAddress) => {
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        setLastPasswordResetEmail(emailAddress)
      })
      .catch(function (error) {
        // An error happened.
      })
  }, [])

  const handleDeleteUser = useCallback(async () => {
    setIsDeleting(true)
    await store
      .collection(CONST.frirebaseCollections.users)
      .doc(userIdToBeDeleted)
      .delete()
    setIsDeleting(false)
    setIsDeleted(true)
  }, [userIdToBeDeleted])

  const showDeleteConfirmationModal = useCallback((userId, userEmail) => {
    setUserIdToBeDeleted(userId)
    setUserEmailToBeDeleted(userEmail)
    setShowDeleteModal(true)
  }, [])

  useEffect(() => {
    if (isDeleted) {
      setTimeout(() => {
        setShowDeleteModal(false)
        setUserIdToBeDeleted(null)
        setUserEmailToBeDeleted(null)
        setIsDeleted(false)
      }, 4000)
    }
  }, [isDeleted])

  if (usersLoading) {
    return (
      <div className="px-4 py-5 sm:px-6 flex flex-col flex-grow flex-1">
        <OLoader />
      </div>
    )
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className=" px-4 py-5 sm:px-6">
          <div className="">
            <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2">
                <h3 className="text-lg md:text-3xl leading-6 font-bold text-hub-dark">
                  Radicle Admins
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:px-6">
          {lastPasswordResetEmail && (
            <div className="rounded-md bg-green-50 p-4 my-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm leading-5 font-medium text-green-800">
                    Password reset link sent to {lastPasswordResetEmail}{' '}
                    <span role="img" aria-label="rocket">
                      🚀
                    </span>
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      onClick={() => {
                        setLastPasswordResetEmail(null)
                      }}
                      className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 bg-white">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        {/* <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th> */}
                        <th className="px-6 py-3 bg-gray-50"></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      <tr key="add-user">
                        <td className="w-full px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 flex items-start justify-start">
                          <Link
                            to={`${url}/add`}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                          >
                            Add admin
                          </Link>
                        </td>
                      </tr>
                      {users && (
                        <>
                          {users.map((user, index) => {
                            return (
                              <tr key={user.id}>
                                <td className="px-6 py-4 whitespace-no-wrap">
                                  <div className="text-sm leading-5 font-medium text-hub-dark">
                                    {`${user.firstName} ${user.lastName}`}
                                  </div>
                                  <div className="text-sm leading-5 text-gray-500">
                                    {user.email}
                                  </div>
                                </td>

                                {/* <td className="px-6 py-4 whitespace-no-wrap">
                                {user.didJoin ? (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                  </span>
                                ) : (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                    Invited
                                  </span>
                                )}
                              </td> */}

                                <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                  <div
                                    onClick={() => {
                                      showDeleteConfirmationModal(
                                        user.id,
                                        user.email
                                      )
                                    }}
                                    className="text-hub-blue hover:text-indigo-900 cursor-pointer"
                                  >
                                    Remove
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => {
          setShowDeleteModal(false)
        }}
        contentLabel="Remove admin"
        style={{
          content: {
            position: 'absolute',
            float: 'left',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className="flex flex-col">
          <X
            className="w-8 self-end cursor-pointer"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          />
          <div className="flex flex-col justify-start py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
              <div className="py-8 px-4 sm:px-10">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Remove account
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to remove{' '}
                        <span className="font-bold text-gray-900">
                          {userEmailToBeDeleted}
                        </span>{' '}
                        admin account? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {isDeleted ? (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {}}
                    >
                      Removed!
                    </button>
                  ) : (
                    <>
                      {!isDeleting ? (
                        <>
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleDeleteUser}
                          >
                            Yes, remove
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => setShowDeleteModal(false)}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => {}}
                        >
                          Removing...
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

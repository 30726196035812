import { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router'
import amplitude from 'amplitude-js'
import CONFIG from '../config/Config'

export const EVENTS = {
  LOGIN: {
    EMAIL_SENT: '[LOGIN] Sign-in initiated - email sent',
    NO_EMAIL: '[LOGIN] Error - email not found',
    LINK_ERROR: '[LOGIN] Error - sign-in link error',
    SUCCESS: '[LOGIN] Sign-in successful',
  },
  REPORT: {
    DOWNLOADED: '[REPORT] Downloaded',
    ERROR: '[REPORT] Downlod error',
    SHARED: '[REPORT] Report shared',
  },
}

const analyticsRoutesAndEvents = {
  '/': {
    eventName: '[NAV] Home',
    eventParams: [],
  },
  '/reports/:reportId': {
    eventName: '[NAV] Report details (direct link)',
    eventParams: ['reportId'],
  },
  '/reports/:reportId/users': {
    eventName: '[NAV] Share report screen',
    eventParams: ['reportId'],
  },
  '/users-for/:organizationId': {
    eventName: '[NAV] Manage users for organization',
    eventParams: ['organizationId'],
  },
  '/users-for/:organizationId/edit/:userId': {
    eventName: '[NAV] Edit user for organization',
    eventParams: ['organizationId', 'userId'],
  },
  '/sign-in': {
    eventName: '[NAV] Sign-in',
    eventParams: [],
  },
}

let initialized = false

function initializeAnalytics() {
  if (!initialized) {
    amplitude.getInstance().init(CONFIG.amplitudeKey)
    initialized = true
  }
}

export function logEvent(eventName, eventProperties = null) {
  initializeAnalytics()
  if (CONFIG.environment === 'production') {
    if (!eventProperties || Object.keys(eventProperties).length === 0) {
      amplitude.getInstance().logEvent(eventName)
    } else {
      amplitude.getInstance().logEvent(eventName, eventProperties)
    }
  } else {
    console.log(eventName, eventProperties)
  }
}

export function describeUser(userId, userProperties = null) {
  initializeAnalytics()
  amplitude.getInstance().setUserId(userId)
  if (userProperties) {
    amplitude.getInstance().setUserProperties(userProperties)
  }
}
export function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    const paths = Object.keys(analyticsRoutesAndEvents)
    let index = 0
    let match = null
    while (!match && index < paths.length) {
      match = matchPath(location.pathname, {
        path: paths[index],
        exact: true,
        strict: false,
      })
      if (!match) {
        index = index + 1
      }
    }
    if (match) {
      const eventName = analyticsRoutesAndEvents[paths[index]].eventName
      const eventParams = analyticsRoutesAndEvents[
        paths[index]
      ].eventParams.reduce((result, paramName) => {
        result[paramName] = match.params[paramName]
        return result
      }, {})
      logEvent(eventName, eventParams)
    } else {
      console.log('NO MATCH for location')
    }
  }, [location])
}

export default amplitude.getInstance()

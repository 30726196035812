const config = {
  environment: 'production',
  firebase: {
    apiKey: 'AIzaSyDB4lI91T3OGHWcKGUVsqSkvYZcbBIhATc',
    authDomain: 'radicle-hub.firebaseapp.com',
    databaseURL: 'https://radicle-hub.firebaseio.com',
    projectId: 'radicle-hub',
    storageBucket: 'radicle-hub.appspot.com',
    messagingSenderId: '179682470741',
    appId: '1:179682470741:web:b3419735522e7c67c49ddc',
  },
  domain: 'https://portal.radicleinsights.com',
  intercomAppId: 'wnkdd8zg',
  amplitudeKey: 'f7b49b40347b0c170bf8e69a46c77774',
}
export default config
// domain: 'http://localhost:3000',
// domain: 'https://portal.radicleinsights.com',

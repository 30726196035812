import CONST from '../config/Constants'

export  function doesUserHaveAccessToReport(claims, reportData) {
  let userHasAccess = false

  if (!reportData.exists) {
    return false
  }

  const orgKey = reportData.organizationId
  if (claims.isRadicleAdmin) {
    userHasAccess = true
  } else if (claims[orgKey]) {
    const access = claims[orgKey]['a']
    if (
      access === CONST.reportAccessTypes.organizationadmin ||
      access === CONST.reportAccessTypes.allreports ||
      access === CONST.reportAccessTypes.shareallreports
    ) {
      userHasAccess = true
    } else if (
      access === CONST.reportAccessTypes.selectedreports ||
      access === CONST.reportAccessTypes.shareselectedreports
    ) {
      const reportIdsUserHasAccess = claims[orgKey]['rids']
      if (reportIdsUserHasAccess.includes(reportData.id)) {
        userHasAccess = true
      }
    }
  }
  return userHasAccess
}

export function isUserOrgAdminForOrg(claims, reportData) {
  if (!reportData.exists) {
    return false
  }
  
  const orgKey = reportData.organizationId

  if (claims.isRadicleAdmin) {
    return true
  } else if (claims[orgKey] && (claims[orgKey]['a'] === CONST.reportAccessTypes.organizationadmin)) {
    return true
  } else {
    return false
  }
}

import React, { useMemo, useEffect } from 'react'
import { useDocument } from '@nandorojo/swr-firestore'

import { Link, useParams } from 'react-router-dom'

import MNoAccess from '../molecules/MNoAccess'
import OLoader from '../organisms/OLoader'
import MEndUserReportPreview from '../molecules/MEndUserReportPreview'
import OManageUsersForReport from '../organisms/OManageUsersForReport'
import OAddUser from '../organisms/OAddUser'
import OAddUserLimitedAccess from '../organisms/OAddUserLimitedAccess'
import CONST from '../config/Constants'
import {
  doesUserHaveAccessToReport,
  isUserOrgAdminForOrg,
} from '../utils/doesUserHaveAccess'

export default function PReportUserAccess({ claims }) {
  const { reportId } = useParams()

  const { data: reportData, loading: reportDataLoading } = useDocument(
    reportId ? `${CONST.frirebaseCollections.reports}/${reportId}` : null,

    { listen: 'true' }
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const doesUserHaveAccess = useMemo(() => {
    let doesHaveAccess = false
    if (claims && reportData && reportData.organizationId) {
      doesHaveAccess = doesUserHaveAccessToReport(claims, reportData)
    }
    return doesHaveAccess
  }, [claims, reportData])

  const canShareOnlyThisReport = useMemo(() => {
    let tmpCanShareOnlyThisReport = false
    if (claims && reportData && reportData.organizationId) {
      if (
        !claims.isRadicleAdmin &&
        claims[reportData.organizationId]['a'] ===
          CONST.reportAccessTypes.shareselectedreports
      ) {
        tmpCanShareOnlyThisReport = true
      }
    }
    return tmpCanShareOnlyThisReport
  }, [claims, reportData])

  const isOrgAdmin = useMemo(() => {
    let isAdmin = false
    if (claims && reportData && reportData.organizationId) {
      isAdmin = isUserOrgAdminForOrg(claims, reportData)
    }
    return isAdmin
  }, [claims, reportData])

  if (reportDataLoading) {
    return <OLoader />
  }

  if (!reportDataLoading && !doesUserHaveAccess) {
    return <MNoAccess />
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg flex-grow my-10">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-1">
            <div className="-ml-4 -mt-2 flex-grow flex items-center flex-wrap sm:flex-no-wrap">
              <div className="ml-4 mt-2 flex flex-grow">
                <h3 className="text-lg md:text-2xl leading-6 font-medium text-hub-blue">
                  <Link to="/">Reports</Link>{' '}
                  <span className="text-xl mr-1 ml-1">{'>'}</span>
                  <span className="text-hub-dark">Report sharing</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-5 sm:px-6">
          <MEndUserReportPreview reportData={reportData} hideEdit getCoverUrl />
          <div className="mt-4"></div>
          {canShareOnlyThisReport ? (
            <OAddUserLimitedAccess
              organizationId={reportData.organizationId}
              reportId={reportData.id}
            />
          ) : (
            <OAddUser
              organizationId={reportData.organizationId}
              reportId={reportData.id}
              claims={claims}
              onlyPublishedReports
            />
          )}
          <div className="hidden sm:block">
            <div className="py-5">
              <div className="border-t border-gray-200"></div>
            </div>
          </div>
          {isOrgAdmin && (
            <div className="px-4 pt-5 sm:px-6">
              <OManageUsersForReport
                key={reportId}
                reportId={reportId}
                organizationId={reportData.organizationId}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

import React from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'

export default function OLoader({ height = 60, width = 60 }) {
  return (
    <div className="flex flex-grow flex-1 mx-auto items-center justify-center ">
      <Loader type="Grid" color="#4340DE" height={height} width={width} />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { Link, useRouteMatch } from 'react-router-dom'

import OLoader from '../organisms/OLoader'

import CONST from '../config/Constants'

import { useFirebaseCollectionDataFromIds } from '../services/Firebase'

//TODO: Handle loading errors

export default function OOrganizationUsers({
  organizationId,
  userEditLinkPrefix,
}) {
  const [willBeNoUsers, setWillBeNoUsers] = useState(false)
  const [usersACLS, setUsersACLS] = useState({})
  const [allUserIds, setAllUserIds] = useState(null)

  const { url } = useRouteMatch()

  const { data: acls, loading: aclsLoading } = useCollection(
    CONST.frirebaseCollections.acl,
    {
      where: ['organizationId', '==', organizationId],
      listen: true,
    }
  )

  const [users] = useFirebaseCollectionDataFromIds(
    CONST.frirebaseCollections.users,
    allUserIds
  )

  useEffect(() => {
    if (!aclsLoading && acls) {
      if (acls.length > 0) {
        let tmpUsersACLS = {}
        const userIds = acls.map((a) => {
          tmpUsersACLS[a.userId] = a
          return a.userId
        })
        setAllUserIds(userIds)
        setUsersACLS(tmpUsersACLS)
      } else {
        setWillBeNoUsers(true)
      }
    }
  }, [acls, aclsLoading, organizationId])

  if (!willBeNoUsers && !users) {
    return (
      <div className="px-4 py-5 sm:px-6 flex flex-col flex-grow flex-1">
        <OLoader />
      </div>
    )
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 bg-white">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Access type
                      </th>
                      {/* <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th> */}
                      <th className="px-6 py-3 bg-gray-50"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    <tr key="add-user">
                      <td className="w-full px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 flex items-start justify-start">
                        <Link
                          to={`${url}/add`}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                          Add user
                        </Link>
                      </td>
                    </tr>
                    {!willBeNoUsers && users && (
                      <>
                        {users.map((user, index) => {
                          let accessTypeLabel = 'Unknown'
                          let howManyReports = 0
                          const acl = usersACLS[user.id]
                          if (!acl) {
                            accessTypeLabel = 'Removed'
                          } else if (
                            acl.reportsAccess ===
                            CONST.reportAccessTypes.organizationadmin
                          ) {
                            accessTypeLabel = 'Org. admin'
                          } else if (
                            acl.reportsAccess ===
                              CONST.reportAccessTypes.allreports ||
                            acl.reportsAccess ===
                              CONST.reportAccessTypes.shareallreports
                          ) {
                            accessTypeLabel = 'All reports'
                          } else if (
                            acl.reportsAccess ===
                              CONST.reportAccessTypes.selectedreports ||
                            acl.reportsAccess ===
                              CONST.reportAccessTypes.shareselectedreports
                          ) {
                            howManyReports = acl.reportIds
                              ? Object.keys(acl.reportIds).length
                              : 0
                            accessTypeLabel = `Selected reports (${howManyReports})`
                          }
                          return (
                            <tr key={user.id}>
                              <td className="px-6 py-4 whitespace-no-wrap">
                                <div className="text-sm leading-5 font-medium text-hub-dark">
                                  {`${user.firstName} ${user.lastName}`}
                                </div>
                                <div className="text-sm leading-5 text-gray-500">
                                  {user.email}
                                </div>
                              </td>

                              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                {accessTypeLabel}
                              </td>
                              {/* <td className="px-6 py-4 whitespace-no-wrap">
                                {user.didJoin ? (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                  </span>
                                ) : (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                    Invited
                                  </span>
                                )}
                              </td> */}
                              <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                <Link
                                  to={`${userEditLinkPrefix}/${user.id}`}
                                  className="text-hub-blue hover:text-indigo-900"
                                >
                                  Edit
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { auth } from '../services/Firebase'

import CONFIG from '../config/Config'

const INTERCOM_APP_ID = CONFIG.intercomAppId

const loadScript = () => {
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`
  script.async = true
  script.setAttribute('data-lazy-loaded', 1)

  return new Promise((resolve) => {
    script.addEventListener('load', function onLoadScript() {
      script.removeEventListener('load', onLoadScript)

      resolve()
    })

    document.body.appendChild(script)
  })
}

const shutdown = () => {
  if (window.Intercom) window.Intercom('shutdown')
}

const MIntercom = ({ data = {} }) => {
  const [user] = useAuthState(auth)

  useEffect(() => {
    const initialize = async () => {
      if (!window.Intercom) {
        await loadScript()
      }
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: false,
        name: user.displayName,
        email: user.email,
        user_id: user.uid,
        ...data,
      })
    }

    if (user) initialize()
  }, [user, data])

  useEffect(() => {
    return shutdown
  }, [])

  return null
}

export default MIntercom

import React, { useState, useMemo, useCallback, useEffect } from 'react'

import { format } from 'date-fns'
import { useAuthState } from 'react-firebase-hooks/auth'

import { Share2, Wifi, DownloadCloud, Link2, Youtube } from 'react-feather'
import { Link } from 'react-router-dom'
import { functions, auth, files } from '../services/Firebase'

import AButton from '../atoms/AButton'

import CONST from '../config/Constants'

import { EVENTS, logEvent } from '../services/Analytics'

//TODO: Handle loading errors
const getReportDownloadURL = functions.httpsCallable('getReportDownloadURL')
export default function MReportPreview({
  reportData,
  coverURL,
  hideEdit,
  getCoverUrl,
  hideLinkSharing,
  setVideoUrlFn = () => {},
}) {
  const [lodingDownloadLink, setLodingDownloadLink] = useState(false)

  const [claims, setClaims] = useState(null)
  const [localCoverURL, setLocalCoverURL] = useState(coverURL)
  const [isLocalCoverLoading, setIsLocalCoverLoading] = useState(true)
  const [user] = useAuthState(auth)

  const handleSettingLocalCoverURL = useCallback((url) => {
    setLocalCoverURL(url)

    setTimeout(() => setIsLocalCoverLoading(false), 150)
  }, [])

  useEffect(() => {
    if (user) {
      user.getIdTokenResult(true).then((tokenResult) => {
        setClaims(tokenResult.claims.radAuth || {})
      })
    }
  }, [user])

  useEffect(() => {
    if (coverURL && !getCoverUrl) {
      handleSettingLocalCoverURL(coverURL)
    }
  }, [coverURL, getCoverUrl, handleSettingLocalCoverURL])

  useEffect(() => {
    if (
      getCoverUrl &&
      reportData &&
      reportData.id &&
      reportData.organizationId
    ) {
      const coverPath = `${reportData.organizationId}/${reportData.id}/cover.png`
      files
        .ref(coverPath)
        .getDownloadURL()
        .then((url) => {
          handleSettingLocalCoverURL(url)
        })
        .catch((err) => {
          setIsLocalCoverLoading(false)
          console.log('Download url error', err)
        })
    }
  }, [getCoverUrl, reportData, handleSettingLocalCoverURL])

  const canShareThisReport = useMemo(() => {
    if (!claims || !reportData) {
      return false
    }

    if (claims.isRadicleAdmin) {
      return true
    } else if (
      claims[reportData.organizationId]['a'] ===
      CONST.reportAccessTypes.organizationadmin
    ) {
      return true
    } else if (
      claims[reportData.organizationId]['a'] ===
      CONST.reportAccessTypes.shareallreports
    ) {
      return true
    } else if (
      claims[reportData.organizationId]['a'] ===
        CONST.reportAccessTypes.shareselectedreports &&
      claims[reportData.organizationId]['rids'].includes(reportData.id)
    ) {
      return true
    } else {
      return false
    }
  }, [claims, reportData])

  const startDownload = useCallback(async () => {
    if (!lodingDownloadLink) {
      setLodingDownloadLink(true)
      try {
        const url = await getReportDownloadURL({ reportId: reportData.id })
        window.location.assign(url.data.downloadURL)
        logEvent(EVENTS.REPORT.DOWNLOADED, {
          reportName: reportData.name,
          reportId: reportData.id,
          organizationId: reportData.organizationId,
        })
      } catch (err) {
        logEvent(EVENTS.REPORT.ERROR, {
          reportName: reportData.name,
          reportId: reportData.id,
          organizationId: reportData.organizationId,
          error: err,
        })
        alert("You don't have access to this report")
      } finally {
        setLodingDownloadLink(false)
      }
    }
  }, [
    lodingDownloadLink,
    reportData.id,
    reportData.name,
    reportData.organizationId,
  ])

  const publishedString = useMemo(() => {
    let tmpPublishedString = 'sometime in the past'
    if (reportData && reportData.createdAt) {
      tmpPublishedString = format(
        reportData.createdAt.toDate(),
        'MMMM dd, yyyy'
      )
    }
    return tmpPublishedString
  }, [reportData])

  return (
    <>
      <li
        key={`${reportData.id}-li`}
        className="col-span-2 bg-white rounded-lg shadow-lg lg:shadow-md grid grid-cols-1 md:grid-cols-2 col-gap-2 p-1"
      >
        {/* Cover column */}
        <div className="flex flex-1 col-span-1 flex-shrink-0">
          <div className="flex-1 flex flex-col px-2 py-4 lg:px-8 lg:py-8 relative">
            <div className="my-2 flex flex-col justify-center items-center content-center  rounded-md bg-gray-50">
              <div className="flex flex-col flex-grow justify-center items-center content-center bg-gray-50 w-full relative">
                <div className="aspect-ratio-16/9 h-0 overflow-hidden" />

                <img
                  src={localCoverURL}
                  alt="Cover"
                  className={`absolute z-10 left-0 top-0 w-full h-full object-cover rounded-md ${
                    !isLocalCoverLoading && 'shadow-xl'
                  }`}
                ></img>

                {isLocalCoverLoading && (
                  <div className="absolute z-20 top-0 left-0 h-full w-full flex flex-grow items-center justify-center border-2 border-dashed bg-gray-50 rounded-md ">
                    <p className="text-sm text-gray-400">Loading cover</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-center items-center flex-shrink-0">
              <div className="mt-4 flex flex-grow-0 mx-auto">
                <AButton
                  fullBorderRadius
                  isLoading={lodingDownloadLink}
                  onClick={startDownload}
                  icon={<DownloadCloud className="text-gray-50 w-3 h-3" />}
                >
                  Download
                </AButton>
              </div>

              <div className="self-center grid grid-cols-2 gap-2 col-gap-2 md:gap-0 md:col-gap-0 md:grid-cols-none md:block mx-auto mt-4">
                {canShareThisReport && (
                  <div className="flex flex-grow-0 mx-auto self-end col-span-1">
                    <Link
                      to={`/reports/${reportData.id}/users`}
                      className="w-36 md:w-auto border-gray-300 md:border-none cursor-pointer flex flex-row justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-hub-dark-gray  hover:bg-gray-100 focus:outline-none "
                    >
                      <Share2 className="text-hub-dark-gray w-3 h-3 mr-2" />
                      <span className="text-xs">Share report</span>
                    </Link>
                  </div>
                )}

                {!hideLinkSharing && (
                  <div
                    className={`flex flex-grow-0 mx-auto self-end col-span-1`}
                  >
                    <Link
                      to={`/reports/${reportData.id}`}
                      className="w-36 md:w-auto border-gray-300 md:border-none cursor-pointer flex flex-row justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-hub-dark-gray  hover:bg-gray-100 focus:outline-none "
                    >
                      <Link2 className="text-hub-dark-gray w-3 h-3 mr-2" />
                      <span className="text-xs">Direct link</span>
                    </Link>
                  </div>
                )}

                {reportData.radarURL && reportData.radarURL.length && (
                  <div className={`flex flex-grow-0 mx-auto self-end`}>
                    <a
                      href={reportData.radarURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-36 md:w-auto border-gray-300 md:border-none cursor-pointer flex flex-row justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-hub-dark-gray  hover:bg-gray-100 focus:outline-none "
                    >
                      <Wifi className="text-hub-dark-gray w-3 h-3 mr-2" />
                      <span className="text-xs">Open Radar</span>
                    </a>
                  </div>
                )}

                {reportData.immersionVideoURL &&
                  reportData.immersionVideoURL.length && (
                    <div className={`flex flex-grow-0 mx-auto self-end`}>
                      <div
                        onClick={() => {
                          setVideoUrlFn(reportData?.immersionVideoURL)
                        }}
                        rel="noopener noreferrer"
                        className="border-gray-300 w-36 md:w-auto md:border-none cursor-pointer flex flex-row justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-full text-hub-dark-gray  hover:bg-gray-100 focus:outline-none "
                      >
                        <Youtube className="text-hub-dark-gray w-3 h-3 mr-2" />
                        <span className="text-xs">Immersion</span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        {/* Metadata column */}
        <div className="flex justify-start col-span-1 px-2 py-2 lg:px-4 lg:py-4 flex-col">
          <div className="mt-0 lg:mt-6">
            {reportData.reportType && (
              <span className="mb-3 lg:mb-4 inline-flex items-center px-3 py-0.5 rounded-sm text-xs font-normal bg-hub-honey-flower text-gray-50">
                {CONST.reportTypes[reportData.reportType] || ''}
              </span>
            )}
            <h3 className="text-hub-dark text-xl leading-5 font-medium">
              {reportData.name}
            </h3>
          </div>

          <dl className="mt-3 flex flex-col">
            <dt className="sr-only">Authors</dt>
            <dd className="text-gray-500 text-sm leading-5">
              {reportData.authors}
            </dd>
          </dl>
          <dl className="mt-4 flex-grow flex flex-col justify-start">
            <dt className="sr-only">Description</dt>
            <dd className="text-gray-700 text-xs leading-5 break-words">
              {reportData.description}
            </dd>
          </dl>
          <dl className={`mt-4 flex-grow flex flex-col justify-end pb-6`}>
            <dt className="sr-only">Published</dt>
            <dd className="text-gray-500 text-xs leading-5">
              {publishedString}
            </dd>
          </dl>
        </div>
      </li>
    </>
  )
}

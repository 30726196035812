import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { auth } from '../services/Firebase'
import OLoader from '../organisms/OLoader'
import { logEvent, EVENTS } from '../services/Analytics'

export default function PSignInWithEmailLink() {
  const { path } = useRouteMatch()

  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      auth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          logEvent(EVENTS.LOGIN.SUCCESS)
          window.localStorage.removeItem('emailForSignIn')
        })
        .catch((error) => {
          logEvent(EVENTS.LOGIN.LINK_ERROR, { error })
          console.log(error)
        })
    }
  }, [path])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <OLoader />
    </div>
  )
}
